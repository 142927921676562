import { Flex, Text, Image, Center, Box } from "@chakra-ui/react";
import { CircularProgressbar,buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import male_icon from "../../assets/male-icon.png";
import female_icon from "../../assets/female-icon.png";
import other_icon from "../../assets/lbtq-icon.png";
import flag from '../../assets/lbtq.png';
interface GenderChartType{
  data:any,
}

const GenderChart = (props:GenderChartType) => {
  const {data} = props;
  const male:number = data && data["पुरुष"];
  const female:number = data && data["महिला"];
  const others:number = data && data["अन्य"];
  const total=(male+female+others)>0?(male+female+others):100;
  const male_percentage = (parseFloat((male/total).toString())*100).toFixed(2);
  const female_percentage = (parseFloat((female/total).toString())*100).toFixed(2);
  const other_percentage = (parseFloat((others/total).toString())*100).toFixed(2);
  
  return (
    <Center width={"90%"} margin={"0 auto"} height={"100%"}>
      <Flex direction="row" justifyContent={"space-between"} gap={10}>
        <Flex direction={"column"} gap={4} alignItems={"center"}>
          <Flex gap={4} alignItems={"center"}>
            <div style={{ width: 120, height: 95 }}>
              <CircularProgressbar
                value={parseFloat(male_percentage)}
                text={`${male_percentage}%`}
                styles={buildStyles({
                  pathColor: `rgba(66, 126, 198, ${male_percentage})`
                })}
              />
            </div>
            <Image
              src={male_icon}
              alt="..."
              borderRadius="full"
              height="70px"
            />
          </Flex>
          <Text fontWeight={500} fontSize={20} mt={3}>
            पुरुष
          </Text>
        </Flex>
        <Flex direction={"column"} gap={4} alignItems={"center"}>
          <Flex gap={4} alignItems={"center"}>
            <div style={{ width: 120, height: 95 }}>
              <CircularProgressbar
                value={parseFloat(female_percentage)}
                text={`${female_percentage}%`}
                styles={buildStyles({
                  pathColor: `rgba(255, 20, 147, ${female_percentage})`
                })}
              />
            </div>
            <Image
              src={female_icon}
              alt="..."
              borderRadius="full"
              height="70px"
            />
          </Flex>
          <Text fontWeight={500} fontSize={20} mt={3}>
            महिला
          </Text>
        </Flex>
        <Flex direction={"column"} gap={4} alignItems={"center"} justifyContent={"center"}>
          <Flex gap={2} alignItems={"center"}>
            <div style={{ width: 120, height: 95 }}>
              <CircularProgressbar
                value={parseFloat(other_percentage)}
                text={`${other_percentage}%`}
                styles={buildStyles({
                  pathColor: `rgba(255, 172, 28, ${other_percentage})`
                })}
              />
            </div>
            <Image
              src={flag}
              alt="..."
              height="30px"
              transform= "rotate(90deg)"
            />
          </Flex>
          <Text fontWeight={500} fontSize={20} mt={3}>
            अन्य
          </Text>
        </Flex>
      </Flex>
    </Center>
  );
};

export default GenderChart;
