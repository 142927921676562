import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import ComponentWrapper from "../../../component/component_wrapper";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { getspecificfiscalyear } from "../../../redux/fiscalyear";

const FiscalYearLayout = (props: {
  title?: string;
  btn_name?: string;
  form_handler?: any;
}) => {
  const dispatch = useAppDispatch();
  const name = useAppSelector((state: any) => state.fiscalyear.name);
  const input_change = (e: any) => {
    dispatch(getspecificfiscalyear(e.target.value));
  };
  return (
    <ComponentWrapper
      title={"आर्थिक वर्ष " + props.title}
      action_btn={props.btn_name}
      form_submit_handler={props.form_handler}
      width="50%"
      icon={<AiOutlineUserAdd />}
    >
      <FormControl isRequired>
        <FormLabel p={0} m={0}>
          आर्थिक वर्ष
        </FormLabel>
        <InputGroup>
          <Input
            fontWeight="500"
            type="text"
            name="name"
            placeholder="आर्थिक वर्ष २०**/ प्रविष्ट गर्नुहोस्"
            value={name}
            onChange={input_change}
          />
        </InputGroup>
      </FormControl>
    </ComponentWrapper>
  );
};
export default FiscalYearLayout;
