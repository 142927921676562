import { Flex } from "@chakra-ui/react";
import SelectInput from "../../../../component/input/input_select";
import TabButton from "../../../../component/button/tab_button";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_hook";
import { set_specific_field } from "../../../../redux/case";
import { check_tab, set_tab_id } from "../../../../redux/tabs";

const options = [
  { label: "भएको", value: 1 },
  { label: "नभएको", value: 2 },
  { label: "आवश्यक नरहेको", value: 3 },
];

const Health = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.case.data);
  const onSubmit = (e: any) => {
    e.preventDefault();
    dispatch(set_tab_id(2))
    dispatch(check_tab(1));
  };
  const select_change = (name: string, value: any) => {
    dispatch(set_specific_field({ name, value }));
  };
  return (
    <form onSubmit={onSubmit}>
      <Flex direction={"column"} gap={5} p={4}>
        <Flex gap={4}>
          <SelectInput
            required
            label="शारीरिक परिषण"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="physical_exam"
            on_change={(e: any) => select_change("physical_exam", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.physical_exam?.toString()
            )}
          />
          <SelectInput
            required
            label="विधि‐विज्ञान तथा चिकित्साजन्य कानूनी परीक्षण"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="fmedical_legal_test"
            on_change={(e: any) => select_change("fmedical_legal_test", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.fmedical_legal_test?.toString()
            )}
          />
        </Flex>
        <Flex gap={4}>
          <SelectInput
            required
            label="एच.आई.भी. स्वेच्छिक परिषण तथा परामर्श सेवा"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="hiv_vol_testing"
            on_change={(e: any) => select_change("hiv_vol_testing", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.hiv_vol_testing?.toString()
            )}
          />
          <SelectInput
            required
            label="गर्भ परीक्षण"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="pregnancy_test"
            on_change={(e: any) => select_change("pregnancy_test", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.pregnancy_test?.toString()
            )}
          />
        </Flex>
        <Flex gap={4}>
          <SelectInput
            required
            label="घाउ चोटपटकको उपचार"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="wounds_treatment"
            on_change={(e: any) => select_change("wounds_treatment", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.wounds_treatment?.toString()
            )}
          />
          <SelectInput
            required
            label="आकस्मिक गर्भ निरोधक सेवा"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="emergency_contraceptive_service"
            on_change={(e: any) => select_change("emergency_contraceptive_service", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.emergency_contraceptive_service?.toString()
            )}
          />
        </Flex>
        <Flex gap={4}>
          <SelectInput
            required
            label="यौन रोगको उपचार"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="sexual_disease_treatment"
            on_change={(e: any) => select_change("sexual_disease_treatment", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.sexual_disease_treatment?.toString()
            )}
          />
          <SelectInput
            required
            label="सुरक्षित गर्भपतन सेवा"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="safe_abortion_service"
            on_change={(e: any) => select_change("safe_abortion_service", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.safe_abortion_service?.toString()
            )}
          />
        </Flex>
        <Flex gap={4}>
          <SelectInput
            required
            label="मानसिक रोगको उपचार"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="mental_disease_treatment"
            on_change={(e: any) => select_change("mental_disease_treatment", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.mental_disease_treatment?.toString()
            )}
          />
          <SelectInput
            required
            label="मनोसामाजिक परामर्श सेवा"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="psychosocial_con_service"
            on_change={(e: any) => select_change("psychosocial_con_service", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.psychosocial_con_service?.toString()
            )}
          />
        </Flex>
        <Flex gap={4} width="49%">
          <SelectInput
            required
            label="अन्य उपचार सेवा"
            placeholder="कृपया छान्नुहोस"
            options={options}
            name="other_treatment_service"
            on_change={(e: any) => select_change("other_treatment_service", e.value)}
            value={options.filter(
              (p: any) => p.value.toString() === data?.other_treatment_service?.toString()
            )}
          />
        </Flex>
      </Flex>
      <TabButton />
    </form>
  );
};
export default Health;
