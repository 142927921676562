import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Flex, Text, Box } from "@chakra-ui/react";
const RadialChart = (props) => {
  const [series] = useState(props.series);
  const [options] = useState({
    chart: {
      type: 'radialBar',
      height: 500
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          }
        }
      }
    },
    colors: ['#1AB7EA', '#0084FF', '#39539E', '#0077B5'],
    labels: props.labels,
    legend: {
      show: true,
      floating: true,
      fontSize: '12px',
      position: 'left',
      offsetX: 0,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: function(seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  });
  return (
        <Box width={props.width}>
        <Flex
         direction={"column"}
         justifyContent={"space-between"}
       >
       <Text
         textAlign={"left"}
         fontSize={"18px"}
         fontWeight={"600"}
         color={"blackAlpha.700"}
       >
         {props.text}
       </Text>
       <ReactApexChart options={options} series={series} type="radialBar" height="500"/>
       </Flex>
     </Box>
  );
}
export default RadialChart;