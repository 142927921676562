import React from "react";
import styles from "./index.module.scss";
import { MdVerified } from "react-icons/md";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_tab_id } from "../../redux/tabs";
interface Tab {
  title: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.tabs?.tab_id);
  const checkedTabs = useAppSelector((state) => state.tabs?.checked_tabs);
  const set_active_tab = (id: number) => {
    dispatch(set_tab_id(id));
  };
  return (
    <div className={styles.tabcontainer}>
      <div className={styles.tab}>
        {tabs.map((tab, index) => (
          <button
            className={[
              activeTab === index ? styles.activeTabButton : "",
              styles.tabButton,
            ].join(" ")}
            key={index}
            onClick={() => set_active_tab(index)}
          >
            {tab.title}
            {checkedTabs
              ? checkedTabs?.includes(index) && <MdVerified />
              : null}
          </button>
        ))}
      </div>
      <div className={styles.tabBody}>
        {tabs[activeTab]?.content}
      </div>
    </div>
  );
};

export default Tabs;
