export function changeIndexToNepali(index: string | number) {
  let nepaliIndex = "";
  if (index !== 0 && !index) {
    return;
  }
  if (typeof index !== "string") {
    index = index.toString();
  }

  for (let i = 0; i < index.length; i += 1) {
    switch (index[i]) {
      case "0":
        nepaliIndex += "०";
        break;
      case "1":
        nepaliIndex += "१";
        break;
      case "2":
        nepaliIndex += "२";
        break;
      case "3":
        nepaliIndex += "३";
        break;
      case "4":
        nepaliIndex += "४";
        break;
      case "5":
        nepaliIndex += "५";
        break;
      case "6":
        nepaliIndex += "६";
        break;
      case "7":
        nepaliIndex += "७";
        break;
      case "8":
        nepaliIndex += "८";
        break;
      case "9":
        nepaliIndex += "९";
        break;
      default:
        nepaliIndex += index[i];
    }
  }
  return nepaliIndex;
}
