import { createSlice } from "@reduxjs/toolkit";
interface ReportState {
    all_reports:any;
}

const initialState: ReportState = {
    all_reports:null,
};

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    set_reports: (state, action) => {
      state.all_reports = action.payload;
    },
  },
});

export const {
    set_reports
} = reportSlice.actions;

export default reportSlice.reducer;
