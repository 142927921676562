import {
  TableContainer,
  Thead,
  Table,
  Th,
  Card,
  Tr,
  Text,
  Flex,
  Divider,
  Td,
  Tbody,
  useToast,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import style from "./index.module.scss";
import SearchBar from "../search_bar";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { useEffect ,useState} from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import httpClients from "../../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { set_reports } from "../../../redux/reports";
import { reset_search_data } from "../../../redux/search_bar";
import { reset_report } from "../../../api/reset_report";
import { changeIndexToNepali } from "../../../hooks/number_translation";
const Report = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const tableRef = useRef(null);
  const toast = useToast();
  const fiscal_year_id = useAppSelector(
    (state: any) => state.search_bar.fiscal_year_id
  );
  const province_id = useAppSelector((state) => state.search_bar.provinces);
  const district_id = useAppSelector((state) => state.search_bar.district);
  const llevel_id = useAppSelector((state) => state.search_bar.llevel);

  const dispatch = useAppDispatch();
  const reports = useAppSelector((state) => state.report.all_reports);
  console.log("Hii");
  console.log(reports);
  const api_call = async () => {
    setLoading(true);

    await httpClients
      .GET(
        `allreports?fiscal_year_id=${fiscal_year_id}${
          province_id ? "&" : "?"
        }province_id=${province_id}${
          province_id ? "&" : "?"
        }district_id=${district_id}${
          province_id ? "&" : "?"
        }llevel_id=${llevel_id}`,
        true
      )
      .then((res) => {
        setLoading(false)
        dispatch(set_reports(res.data));
      })
      .catch((err) => {
    setLoading(false);
        toast({
          title: "Error occured while fetching reports",
          status: "error",
        });
      });
  };
  useEffect(() => {
    dispatch(reset_search_data());
    api_call();
  }, [dispatch]);
  console.log(reports);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "case_report",
    sheet: "Users",
  });
  const reset_search_handler = async () => {
    dispatch(reset_search_data());
    const response = await reset_report(
      fiscal_year_id,
      province_id,
      district_id,
      llevel_id
    );
    dispatch(set_reports(response.data.data));
  };
  return (
    <Flex direction={"column"} gap={4}>
      <SearchBar search_handler={api_call} reset_btn={reset_search_handler} />
      <button className="file_button" onClick={onDownload}>
        <AiOutlineCloudDownload />
        डाउनलोड तालिका
      </button>
      <Card p={4}>
        <Text fontSize={"28px"} fontWeight={600}>
          केस रिपोर्ट
        </Text>
        <Divider />
        {!loading && reports && (
          <TableContainer ref={tableRef} pt={2}>
            <Table className={style.report}>
              <Thead>
                <Tr>
                  <Th
                    colSpan={
                      Object.keys(reports.monthlyreport.age).length +
                      Object.keys(reports.monthlyreport.gender).length +
                      Object.keys(reports.monthlyreport.voilence_type).length +
                      Object.keys(reports.monthlyreport.others).length
                    }
                  >
                    लैङ्गिक हिंसाबाट प्रभावितहरुलाई प्रदान गरिएका सेवाहरुको
                    मासिक प्रगति विवरण
                  </Th>
                </Tr>
                <Tr>
                  <Th rowSpan={3} className={style.table_head_fix_1}>
                    क्र.स.
                  </Th>
                  <Th rowSpan={3} className={style.table_head_fix_2}>
                    विवरण
                  </Th>
                  <Th colSpan={Object.keys(reports.monthlyreport.age).length}>
                    उमेर समुह
                  </Th>
                  <Th
                    colSpan={Object.keys(reports.monthlyreport.gender).length}
                  >
                    लिङ्ग
                  </Th>
                  <Th
                    colSpan={
                      Object.keys(reports.monthlyreport.voilence_type).length
                    }
                  >
                    हिंसाका प्रकार
                  </Th>
                  <Th
                    colSpan={
                      Object.keys(reports.monthlyreport.affected_victim).length
                    }
                  >
                    प्रेषण गरी पठाउने निकाय र संस्था
                  </Th>
                  <Th
                    colSpan={Object.keys(reports.monthlyreport.others).length}
                  >
                    प्रेषण गरी पठाएको निकाय र सेवा
                  </Th>
                </Tr>
                <Tr>
                  {Object.keys(reports.monthlyreport.age).map((p: any) => (
                    <Th>{p}</Th>
                  ))}
                  {Object.keys(reports.monthlyreport.gender).map((p: any) => (
                    <Th>{p}</Th>
                  ))}
                  {Object.keys(reports.monthlyreport.voilence_type).map(
                    (p: any) => (
                      <Th>{p}</Th>
                    )
                  )}
                  {Object.keys(reports.monthlyreport.affected_victim).map(
                    (p: any) => (
                      <Th>{p}</Th>
                    )
                  )}
                  {Object.keys(reports.monthlyreport.others).map((p: any) => (
                    <Th>{p}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td className={style.table_head_fix_1}>
                    {changeIndexToNepali(1)}
                  </Td>
                  <Td className={style.table_head_fix_2}>साप्ताहिक रिपोर्ट</Td>
                  {Object.values(reports.weeklyreport.age).map((p: any) => (
                    <Td>{changeIndexToNepali(p)}</Td>
                  ))}
                  {Object.values(reports.weeklyreport.gender).map((p: any) => (
                    <Td>{changeIndexToNepali(p)}</Td>
                  ))}
                  {Object.values(reports.weeklyreport.voilence_type).map(
                    (p: any) => (
                      <Td>{changeIndexToNepali(p)}</Td>
                    )
                  )}
                  {Object.values(reports.weeklyreport.affected_victim).map(
                    (p: any) => (
                      <Td>{changeIndexToNepali(p)}</Td>
                    )
                  )}
                  {Object.values(reports.weeklyreport.others).map((p: any) => (
                    <Td>{changeIndexToNepali(p["भएको"])}</Td>
                  ))}
                </Tr>
                <Tr>
                  <Td className={style.table_head_fix_1}>
                    {changeIndexToNepali(2)}
                  </Td>
                  <Td className={style.table_head_fix_2}>मासिक रिपोर्ट</Td>
                  {Object.values(reports.monthlyreport.age).map((p: any) => (
                    <Td>{changeIndexToNepali(p)}</Td>
                  ))}
                  {Object.values(reports.monthlyreport.gender).map((p: any) => (
                    <Td>{changeIndexToNepali(p)}</Td>
                  ))}
                  {Object.values(reports.monthlyreport.voilence_type).map(
                    (p: any) => (
                      <Td>{changeIndexToNepali(p)}</Td>
                    )
                  )}
                  {Object.values(reports.monthlyreport.affected_victim).map(
                    (p: any) => (
                      <Td>{changeIndexToNepali(p)}</Td>
                    )
                  )}
                  {Object.values(reports.monthlyreport.others).map((p: any) => (
                    <Td>{changeIndexToNepali(p["भएको"])}</Td>
                  ))}
                </Tr>
                <Tr>
                  <Td className={style.table_head_fix_1}>
                    {changeIndexToNepali(3)}
                  </Td>
                  <Td className={style.table_head_fix_2}>वार्षिक रिपोर्ट</Td>
                  {Object.values(reports.yearlyreport.age).map((p: any) => (
                    <Td>{changeIndexToNepali(p)}</Td>
                  ))}
                  {Object.values(reports.yearlyreport.gender).map((p: any) => (
                    <Td>{changeIndexToNepali(p)}</Td>
                  ))}
                  {Object.values(reports.yearlyreport.voilence_type).map(
                    (p: any) => (
                      <Td>{changeIndexToNepali(p)}</Td>
                    )
                  )}
                  {Object.values(reports.yearlyreport.affected_victim).map(
                    (p: any) => (
                      <Td>{changeIndexToNepali(p)}</Td>
                    )
                  )}
                  {Object.values(reports.yearlyreport.others).map((p: any) => (
                    <Td>{changeIndexToNepali(p["भएको"])}</Td>
                  ))}
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        )}
        
      </Card>
      {loading && (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Flex>
  );
};
export default Report;
