import {
  Button,
  Center,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_modal } from "../../redux/extra_field";
import { IoWarningOutline } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoMdRemoveCircleOutline } from "react-icons/io";

function DeleteModal(props: { delete_handler?: any }) {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((state) => state.extraField.modal);
  return (
    <Center>
      <Modal
        closeOnOverlayClick={true}
        isOpen={modal as any}
        onClose={() => dispatch(set_modal(false))}
        isCentered={true}
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>डेटा मेट्न चाहनुहुन्छ?</ModalHeader>
          <ModalCloseButton />
          <Divider borderColor={"#ccc"} />
          <ModalBody>
            <Center>
            <Text
              textAlign={"center"}
              fontSize={50}
              fontWeight={500}
              color={"red.600"}
            >
              <IoWarningOutline />
            </Text>
            </Center>
            <Text
              textAlign={"center"}
              fontSize={20}
              mb={3}
              mt={3}
              fontWeight={500}
            >
              Are You Sure ?
            </Text>
            <Text color="blue.600">
              <Text as="span" color="red">
                Note:
              </Text>{" "}
              This record and it's detail will be permanantly deleted !!
            </Text>
          </ModalBody>
          <ModalFooter pt={0}>
            <Button
              leftIcon={<MdOutlineDeleteOutline />}
              colorScheme="red"
              mr={3}
              onClick={props.delete_handler}
            >
              मेट्नुहोस्
            </Button>
            <Button
              leftIcon={<IoMdRemoveCircleOutline />}
              onClick={() => dispatch(set_modal(false))}
            >
              रद्द गर्नुहोस्
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
}
export default DeleteModal;
