import { createSlice } from "@reduxjs/toolkit";
interface AffectedServicesState {
  data: Array<Object>;
  name: string;
}

const initialState: AffectedServicesState = {
  data: [],
  name: "",
};

export const AffectedServicesSlice = createSlice({
  name: "AffectedServicesSlice",
  initialState,
  reducers: {
    setaffectedservices: (state, action) => {
      state.data = action.payload;
    },
    getspecificservices: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setaffectedservices, getspecificservices } =
  AffectedServicesSlice.actions;

export default AffectedServicesSlice.reducer;
