import {
  Flex,
  InputGroup,
  Text,
  useToast,
  FormControl,
  Input,
  FormLabel,
  Stack,
  Card,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import httpClients from "../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import ViewWrapper from "../../../component/component_wrapper/view_wrapper";

const ViewUser = () => {
  const toast = useToast();
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  useEffect(() => {
    httpClients
      .GET("users/" + id, true)
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error occured while fetching user",
          status: "error",
        });
      });
  }, [id, toast]);
  return (
    <ViewWrapper width="70%" title="प्रयोगकर्ता विवरण">
      <Flex direction={"column"} gap={2}>
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Full Name
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user?.name} />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Position
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user?.roles[0]?.role} />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Email
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user?.email} />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Phone Number
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user?.phone_number} />
            </InputGroup>
          </FormControl>
        </Flex>{" "}
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Address
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user?.address} />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Designation
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user?.designation?.designation} />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Office
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={user?.office?.office_name}
              />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Office Address
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                name="email"
                value={user?.office?.office_address}
              />
            </InputGroup>
          </FormControl>
        </Flex>{" "}
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Office Email
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={user?.office?.office_email}
              />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              Office Phone
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={user?.office?.office_phone}
              />
            </InputGroup>
          </FormControl>
        </Flex>
      </Flex>
    </ViewWrapper>
  );
};
export default ViewUser;
