import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import {
  getspecificfiscalyear,
  setfiscalyear,
} from "../../../redux/fiscalyear";
import FiscalYearLayout from ".";
const UpdateFiscalYear = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const name = useAppSelector((state) => state.fiscalyear.name);
  React.useEffect(() => {
    httpClients
      .GET("fiscalyears/" + id, true)
      .then((res) => {
        dispatch(getspecificfiscalyear(res.data.data.name));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching gender data!",
          status: "error",
        });
      });
  }, [dispatch]);
  const updategender = (e: any) => {
    e.preventDefault();
    httpClients
      .PUT(
        "fiscalyears/" + id,
        {
          name,
        },
        true
      )
      .then((res) => {
        toast({ title: "Fiscal Year has been updated!", status: "success" });
        httpClients.GET("fiscalyears", true).then((res) => {
          dispatch(setfiscalyear(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating gender!",
          status: "error",
        });
      });
  };
  return (
    <FiscalYearLayout
      form_handler={updategender}
      btn_name="सम्पादन"
      title="सम्पादन"
    />
  );
};
export default UpdateFiscalYear;
