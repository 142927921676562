import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_hook";
import style from "./index.module.scss";
import TabButton from "../../../../component/button/tab_button";
import httpClients from "../../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { check_tab } from "../../../../redux/tabs";
import { MdOutlineCancel } from "react-icons/md";
const UsefulDocs = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any>([]);
  const status = useAppSelector((state) => state.case.status);
  const toast = useToast();
  const data = useAppSelector((state) => state.case.data);
  const [files, setFiles] = useState<any>([]);
  const fileRef = useRef<any>();
  const fileHandler = () => {
    if (fileRef !== null) {
      fileRef.current.click();
    }
  };
  const changeHandler = (e: any) => {
    let validFiles = Object.keys(e.target.files).map((p) => e.target.files[p]);
    setDocuments([...documents, ...validFiles.filter((p) => p !== undefined)]);
    let file_names: any = [];
    for (let i = 0; i < e.target.files.length; i++) {
      file_names = [...file_names, e.target.files[i].name];
    }
    setFiles(file_names);
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    dispatch(check_tab(3));
    if (status === "create") {
      httpClients
        .POST("gvs", { ...data, files: documents }, true)
        .then((res) => {
          console.log(data);
          toast({ title: "तपाईंको केस पेश गरिएको छ!", status: "success" });
          navigate(-1);
        })
        .catch((err) => {
          toast({
            title: "तपाईँको केस पठाउँदा त्रुटि उत्पन्न भयो!",
            status: "error",
          });
        });
    }
    if (status === "update") {
      httpClients
        .PUT("gvs/" + id, { ...data, files: documents }, true, true)
        .then((res) => {
          toast({ title: "तपाईंको केस पेश गरिएको छ!", status: "success" });
          navigate(-1);
        })
        .catch((err) => {
          toast({
            title: "तपाईँको केस पठाउँदा त्रुटि उत्पन्न भयो!",
            status: "error",
          });
        });
    }
  };
  const remove_file = (index: number) => {
    setFiles(files.filter((p: any, i: any) => i !== index));
    setDocuments(documents.filter((p: any, i: any) => i !== index));
  };
  return (
    <form onSubmit={onSubmit}>
      <Box p={4}>
        <Text fontSize={"18px"} fontWeight={"500"} color={"red.600"}>
          नोट: केस सम्बन्धित कागजातहरू अपलोड गर्नुहोस्, एक भन्दा बढी फाइलहरू
          अपलोड गर्न सक्नुहुन्छ |
        </Text>
        <Flex p={"10px 0"} gap={10} alignItems={"flex-start"}>
          <div
            onClick={fileHandler}
            className={style.file_button}
            style={{ width: "max-content", cursor: "pointer" }}
          >
            Upload Documents
          </div>
          <Flex direction={"column"} gap={2}>
            {files &&
              files.map((p: any, index: number) => (
                <Flex gap={2} direction={"row"} alignItems={"center"}>
                  <Text color={"red.500"}>{p}</Text>
                  <MdOutlineCancel
                    className={style.cancel_button}
                    onClick={() => remove_file(index)}
                  />
                </Flex>
              ))}
          </Flex>
        </Flex>

        <input
          name="files[]"
          style={{ display: "none" }}
          type="file"
          onChange={changeHandler}
          multiple
          ref={fileRef}
          accept="
          application/pdf,application/vnd.ms-excel,image/*
          "
        />
      </Box>
      <TabButton />
    </form>
  );
};
export default UsefulDocs;
