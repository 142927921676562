import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { getspecificcaste, setcaste } from "../../../redux/caste_tribes";
import CasteLayout from ".";
const CreateCaste = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.caste.name);
  React.useEffect(() => {
    dispatch(getspecificcaste(""));
  }, [dispatch]);
  const createcaste = (e: any) => {
    e.preventDefault();
    httpClients
      .POST("castetribes", {name}, true)
      .then((res) => {
        toast({
          title: "Caste type has been created!",
          status: "success",
        });
        httpClients.GET("castetribes", true).then((res) => {
          dispatch(setcaste(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating caste type!",
          status: "error",
        });
      });
  };
  return (
    <CasteLayout
      form_handler={createcaste}
      btn_name="थप्नुहोस्"
      title="थप्नुहोस्"
    />
  );
};
export default CreateCaste;
