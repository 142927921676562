import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import {
  getspecificservices,
  setaffectedservices,
} from "../../../redux/affected_services";
import AffectedServicesLayout from ".";
const UpdateAffectedServices = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const name = useAppSelector((state) => state.affectedservices.name);
  React.useEffect(() => {
    httpClients
      .GET("affectedservices/" + id, true)
      .then((res) => {
        console.log("-->"+JSON.stringify(res.data.data.name));
        dispatch(getspecificservices(res.data.data.name));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching affected services data!",
          status: "error",
        });
      });
  }, [dispatch]);
  const updatecaste = (e: any) => {
    e.preventDefault();
    httpClients
      .PUT("affectedservices/" + id, { name }, true)
      .then((res) => {
        toast({ title: "affected services has been updated!", status: "success" });
        navigate(-1);
        httpClients.GET("affectedservices", true).then((res) => {
          dispatch(setaffectedservices(res.data.data));
        });
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating affected services!",
          status: "error",
        });
      });
  };
  return (
    <AffectedServicesLayout
      form_handler={updatecaste}
      btn_name="सम्पादन"
      title="सम्पादन"
    />
  );
};
export default UpdateAffectedServices;
