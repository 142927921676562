import { Button, Card, Center, Flex, Text } from "@chakra-ui/react";
import { AiOutlineSend } from "react-icons/ai";
import { CiCircleRemove } from "react-icons/ci";
import { useNavigate } from "react-router";

const ComponentWrapper = (props: {
  title?: string;
  icon?: JSX.Element;
  children?: JSX.Element[] | JSX.Element;
  width?: string;
  action_btn?: string;
  form_submit_handler?: any;
}) => {
  const navigate = useNavigate();
  return (
    <Center height="100%">
      <Card bgColor={"#fff"} p={4} width={props.width}>
        <Flex
          fontSize={"28px"}
          fontWeight={500}
          pb={4}
          gap={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {props.icon}
          <Text>{props.title}</Text>
        </Flex>
        <form onSubmit={props.form_submit_handler}>
          <Flex direction={"column"} gap={4}>
            {props.children}
          </Flex>
          <Flex gap={2} justifyContent={"flex-end"} pt={"10px"}>
            <Button
              leftIcon={<CiCircleRemove />}
              variant={"outline"}
              onClick={() => navigate(-1)}
              type="button"
            >
              रद्द गर्नुहोस्
            </Button>

            <Button
              variant={"solid"}
              colorScheme="blue"
              rightIcon={<AiOutlineSend />}
              type="submit"
            >
              {props.action_btn}
            </Button>
          </Flex>
        </form>
      </Card>
    </Center>
  );
};
export default ComponentWrapper;
