import { createSlice } from "@reduxjs/toolkit";
interface AffectedVictimState {
  data: Array<Object>;
  name: string;
}

const initialState: AffectedVictimState = {
  data: [],
  name: "",
};

export const AffectedVictimSlice = createSlice({
  name: "AffectedVictimSlice",
  initialState,
  reducers: {
    setaffectedvictims: (state, action) => {
      state.data = action.payload;
    },
    getspecificvictims: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setaffectedvictims, getspecificvictims } =
  AffectedVictimSlice.actions;

export default AffectedVictimSlice.reducer;
