import {
  Stack,
  Text,
  Image,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  WrapItem,
  Avatar,
  Divider,
  useToast,
  Flex,
  Box,
  AvatarBadge,
  AvatarGroup,
} from "@chakra-ui/react";
import { AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import GovIcon from "../../../assets/mofoga.png";
import { BiChevronDown, BiLogOut } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { setLogin } from "../../../redux/login";
import { useNavigate } from "react-router";
import style from "./index.module.scss";
import PasswordChange from "../../../component/modal/setting";
import {
  set_change_password,
  set_description_modal,
  set_loginuser_data,
} from "../../../redux/extra_field";
import httpClients from "../../../utils/httpClients";
import React from "react";
import { FiHelpCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import ViewDescription from "../../../component/modal/view_description";
const NavBar = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const user_data = useAppSelector((state) => state.extraField.loginuser_data);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    httpClients
      .GET("users/" + localStorage.getItem("user_id"), true)
      .then((response) => {
        dispatch(set_loginuser_data(response.data.data));
      });
  }, [dispatch]);
  const logoutHandler = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(setLogin());
    navigate("/");
    toast({
      title: "तपाईँ सफलतापूर्वक लग आउट हुनुभएको छ!",
      status: "success",
    });
  };

  return (
    <div className={style.navbar_admin}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={"4"}
        borderBottom={"1px solid #ccc"}
      >
        <Stack
          direction={"row"}
          spacing={2}
          onClick={() => navigate("/")}
          cursor={"pointer"}
        >
          <Image
            src={GovIcon}
            alt="Goverment Icon "
            objectFit="cover"
            height="75px"
          />
          <Text fontWeight={"500"}>
            <Text display={"inline"} color={"red.500"} fontSize={"16px"}>
              लैङ्गिक हिंसा मुद्दा व्यवस्थापन प्रणाली <br />
            </Text>
            <Text display={"inline"} color={"blue.400"} fontSize={"18px"}>
              कर्णाली प्रदेश सरकार सामाजिक विकास मन्त्रालय
            </Text>
            <br />
            <span style={{ fontSize: "14px" }}>विरेन्द्रनगर,सुर्खेत,नेपाल</span>
          </Text>
        </Stack>
        <Flex alignItems={"center"} gap={8}>
          <Stack direction={"row"} mx={5} spacing={5} align="center">
            <Text
              className="nav_link"
              onClick={() => dispatch(set_description_modal())}
            >
              प्रणाली वर्णन
              <FiHelpCircle />
            </Text>
            <NavLink
              to={"/profile"}
              className={({ isActive }) =>
                isActive ? "nav_link nav_link_active" : "nav_link"
              }
            >
              प्रोफाइल
              <AiOutlineUser />
            </NavLink>
          </Stack>
          <Stack direction={"row"} spacing={"14"}>
            <Menu>
              <MenuButton>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <WrapItem>
                    <AvatarGroup>
                      <Avatar bg="blue.500" height={"2.5rem"} width={"2.5rem"}>
                        <AvatarBadge boxSize="1em" bg="green.500" />
                      </Avatar>
                    </AvatarGroup>
                  </WrapItem>
                  <BiChevronDown />
                </Stack>
              </MenuButton>
              <MenuList>
                <Flex
                  gap={2}
                  p={2}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  direction={"column"}
                >
                  <Text
                    bgColor={"blue.600"}
                    color={"#fff"}
                    p={"2px 6px"}
                    borderRadius={5}
                    textTransform={"uppercase"}
                  >
                    {user_data?.designation?.designation}
                  </Text>
                  <Text>{user_data?.name}</Text>
                </Flex>
                <Divider></Divider>
                {/* <MenuItem
                  className="item_gap"
                  fontSize={18}
                  onClick={() => navigate("/profile")}
                >
                  <AiOutlineUser />
                  प्रोफाइल
                </MenuItem> */}
                <Divider></Divider>
                <MenuItem
                  className="item_gap"
                  fontSize={18}
                  onClick={() => dispatch(set_change_password())}
                >
                  <AiOutlineSetting />
                  सेटिङ
                </MenuItem>
                <Divider />
                <MenuItem
                  className="item_gap"
                  fontSize={18}
                  onClick={logoutHandler}
                >
                  <BiLogOut />
                  लगआउट
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
      </Stack>
      <PasswordChange />
      <ViewDescription />
    </div>
  );
};
export default NavBar;
