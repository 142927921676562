import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  useToast,
  Box,
} from "@chakra-ui/react";
import SelectInput from "../../../component/input/input_select";
import ComponentWrapper from "../../../component/component_wrapper";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { useEffect } from "react";
import httpClients from "../../../utils/httpClients";
import {
  set_district,
  set_llevel,
  set_province,
} from "../../../redux/extra_field";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { setofficetype } from "../../../redux/office_type";
import { setSpecificoffice } from "../../../redux/office";
const options = [
  {
    label: "निष्क्रिय",
    value: "0",
  },
  {
    label: "सक्रिय",
    value: "1",
  },
];
const OfficeLayout = (props: {
  btn_name?: string;
  form_handler?: any;
  title?: string;
  password: boolean;
}) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const provinces = useAppSelector((state) => state.extraField.provinces);
  const districts = useAppSelector((state) => state.extraField.district);
  const llevel = useAppSelector((state) => state.extraField.llevel);

  const office_types = useAppSelector((state: any) => state.office_type.data);
  const specific_office = useAppSelector(
    (state) => state.office.specific_office
  );
  useEffect(() => {
    httpClients
      .GET("/provinces", true)
      .then((res: any) => {
        console.log(res.data);
        dispatch(set_province(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching provinces",
          status: "error",
        });
      });
    httpClients
      .GET("/districts", true)
      .then((res: any) => {
        dispatch(set_district(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching districts",
          status: "error",
        });
      });
    httpClients
      .GET("/llevels", true)
      .then((res: any) => {
        dispatch(set_llevel(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching districts",
          status: "error",
        });
      });
    httpClients
      .GET("/officetypes", true)
      .then(async (res: any) => {
        dispatch(setofficetype(res.data.data));
      })
      .catch((err: any) => {
        toast({
          title: "Error occured while fetching office types!",
          status: "error",
        });
      });
  }, [dispatch]);
  const input_change = (e: any) => {
    dispatch(setSpecificoffice({ name: e.target.name, value: e.target.value }));
  };
  const select_change = (name: string, value: any) => {
    dispatch(setSpecificoffice({ name, value }));
  };
  return (
    <ComponentWrapper
      icon={<HiOutlineBuildingOffice2 />}
      form_submit_handler={props.form_handler}
      title={"कार्यालय " + props.title}
      width="70%"
      action_btn={props.btn_name}
    >
      <Flex gap={8}>
        <SelectInput
          label="प्रदेश"
          required
          placeholder="प्रदेश छान्नुहोस्"
          name="province_id"
          value={
            provinces &&
            provinces
              .filter((p: any) => p.id === 6)
              .map((p: any) => {
                return { value: p?.id, label: p?.province_np };
              })
          }
        />
        <SelectInput
          label="जिल्ला"
          required
          placeholder="जिल्ला छान्नुहोस्"
          name="district_id"
          options={
            districts &&
            districts
              .filter((p: any) => p?.province?.id === 6)
              ?.map((p: any) => {
                return { value: p?.did, label: p?.district_np };
              })
          }
          value={
            districts &&
            districts
              .map((p: any) => {
                return { label: p.district_np, value: p.did };
              })
              ?.filter(
                (p: any) =>
                  p.value.toString() ===
                  specific_office?.district_id?.toString()
              )
          }
          on_change={(e: any) => select_change("district_id", e.value)}
        />
      </Flex>
      <Flex gap={8}>
        <SelectInput
          label="स्थानीय तह"
          required
          placeholder=" स्थानीय तह छान्नुहोस्"
          name="lg_id"
          options={
            llevel &&
            llevel
              .filter(
                (p: any) =>
                  p?.district?.did?.toString() ===
                  specific_office?.district_id?.toString()
              )
              ?.map((p: any) => {
                return { value: p?.lgid, label: p?.lgname_np };
              })
          }
          value={
            llevel &&
            llevel
              .map((p: any) => {
                return { label: p.lgname_np, value: p.lgid };
              })
              ?.filter(
                (p: any) =>
                  p.value.toString() === specific_office?.lg_id?.toString()
              )
          }
          on_change={(e: any) => select_change("lg_id", e.value)}
        />
        <SelectInput
          label="कार्यालय प्रकार"
          required
          placeholder="कार्यालय प्रकार छान्नुहोस्"
          name="office_type_id"
          options={office_types.map((p: any) => {
            return {
              label: p.office_type,
              value: p.id,
            };
          })}
          on_change={(e: any) => select_change("office_type_id", e.value)}
          value={office_types
            .map((p: any) => {
              return {
                label: p.office_type,
                value: p.id,
              };
            })
            ?.filter(
              (p: any) =>
                p.value?.toString() ===
                specific_office?.office_type_id?.toString()
            )}
        />
      </Flex>
      <Flex gap={8}>
        <FormControl isRequired>
          <FormLabel p={0} m={0}>
            कार्यालय नाम / विभाग
          </FormLabel>
          <InputGroup>
            <Input
              type="text"
              name="office_name"
              placeholder="कार्यालयको नाम वा विभाग नाम प्रविष्ट गर्नुहोस्"
              value={specific_office.office_name}
              onChange={input_change}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel p={0} m={0}>
            कार्यालय ठेगाना
          </FormLabel>
          <InputGroup>
            <Input
              type="text"
              name="office_address"
              placeholder="कार्यालय ठेगाना प्रविष्ट गर्नुहोस्"
              onChange={input_change}
              value={specific_office.office_address}
            />
          </InputGroup>
        </FormControl>
      </Flex>
      <Flex gap={8}>
        <FormControl>
          <FormLabel p={0} m={0}>
            कार्यालयको फोन नम्बर
          </FormLabel>
          <InputGroup>
            <Input
              type="number"
              name="office_phone"
              value={specific_office?.office_phone}
              onChange={input_change}
              placeholder="कार्यालयको फोन नम्बर प्रविष्ट गर्नुहोस्"
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel p={0} m={0}>
            कार्यालयको इमेल
          </FormLabel>
          <InputGroup>
            <Input
              type="email"
              name="office_email"
              placeholder="कार्यालयको इमेल प्रविष्ट गर्नुहोस्"
              value={specific_office?.office_email}
              onChange={input_change}
            />
          </InputGroup>
        </FormControl>
      </Flex>
      <Flex gap={8}>
        <FormControl>
          <FormLabel p={0} m={0}>
            कार्यालयको अक्षांश
          </FormLabel>
          <InputGroup>
            <Input
              type="number"
              name="latitude"
              placeholder="कार्यालयको अक्षांश प्रविष्ट गर्नुहोस्"
              onChange={input_change}
              value={specific_office?.latitude}
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel p={0} m={0}>
            कार्यालयको देशान्तर
          </FormLabel>
          <InputGroup>
            <Input
              type="number"
              name="longitude"
              placeholder="कार्यालयको देशान्तर प्रविष्ट गर्नुहोस्"
              onChange={input_change}
              value={specific_office?.longitude}
            />
          </InputGroup>
        </FormControl>
      </Flex>
      <Box width={"50%"}>
        <SelectInput
          label="स्थिति"
          required
          placeholder="स्थिति छान्नुहोस्"
          options={options}
          name="is_active"
          value={options.filter(
            (p: any) =>
              p.value.toString() === specific_office?.is_active.toString()
          )}
          on_change={(e: any) => select_change("is_active", e.value)}
        />
      </Box>
    </ComponentWrapper>
  );
};
export default OfficeLayout;
