import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  Flex,
  Text,
  Image,
  AccordionPanel,
} from "@chakra-ui/react";
import { useAppSelector } from "../../../../hooks/redux_hook";
import style from '../index.module.scss'
const LinkWrapper = (props: {
  title?: string;
  children?: JSX.Element | JSX.Element[];
  click_handler?: any;
  icon?: JSX.Element;
  active?: boolean;
}) => {
  const sidebar = useAppSelector((state) => state.sidebar.sidebar);
  return (
    <AccordionItem
      borderStyle={"none"}
      className="accordion_button"
      onClick={props.click_handler}
    >
      <h2>
        <AccordionButton
          backgroundColor={props.active ? "#ebf4ff" : "transparent"}
          _expanded={{ bgColor: "#ebf4ff" }}
          borderTop="none"
        >
          <Flex
            flex="1"
            textAlign="left"
            gap={2}
            alignItems={"center"}
            p={sidebar ? 1 : 3}
            justifyContent={sidebar ? "flex-start" : "center"}
          >
            <Text className={[props.active && style.svg_active,""].join(" ")}>{props.icon}</Text>
            {sidebar && (
              <Text fontSize={"20px"} color={props.active ? "blue.600":"#5c6c74"} fontWeight={"500"}>
                {props.title}
              </Text>
            )}
          </Flex>
          {sidebar && props.children && <AccordionIcon />}
        </AccordionButton>
      </h2>
      {sidebar && props.children && (
        <AccordionPanel pb={0} width="100%">
          {props.children}
        </AccordionPanel>
      )}
    </AccordionItem>
  );
};
export default LinkWrapper;
