import { useEffect, useState } from "react";
import ViewWrapper from "../../../component/component_wrapper/view_wrapper";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import style from "./index.module.scss";
const ViewPermission = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<any>();
  const [users, setUser] = useState<any>();
  const [roles, setRoles] = useState<any>();
  useEffect(() => {
    httpClients
      .GET("/permissions/" + 1, true)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        console.log(res.data.data);
        setUser(res.data.data.roles[0].users);
        setRoles(res.data.data.roles);
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching permission",
          status: "error",
        });
      });
  }, []);
  return (
    <ViewWrapper title={data?.permission_name} width="60%">
      <div className={style.table}>
        <div className={style.section}>
          <p className={style.head}>allowed roles</p>
          <div>
            {roles &&
              roles.map((p: any) => (
                <p
                  className={style.children}
                  onClick={() => navigate("/role/view/" + p.id)}
                >
                  {p.role}
                </p>
              ))}
          </div>
        </div>
        <div className={style.section}>
          <p className={style.head}>allowed users</p>
          <div>
            {users &&
              users.map((p: any) => (
                <p
                  onClick={() => navigate("/user/view/" + p.id)}
                  className={style.children}
                >
                  {p.name}
                </p>
              ))}
          </div>
        </div>
      </div>
    </ViewWrapper>
  );
};
export default ViewPermission;
