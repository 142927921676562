import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  useToast,
  InputLeftElement,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { BiUser } from "react-icons/bi";
import SelectInput from "../../../component/input/input_select";
import ComponentWrapper from "../../../component/component_wrapper";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { useEffect, useState } from "react";
import httpClients from "../../../utils/httpClients";
import {
  set_designation,
  set_office,
  set_role,
} from "../../../redux/extra_field";
import { setSpecificuser } from "../../../redux/user";

const UserLayout = (props: {
  btn_name?: string;
  form_handler?: any;
  title?: string;
  password: boolean;
}) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const office = useAppSelector((state) => state.extraField.office);
  const designation = useAppSelector((state) => state.extraField.designation);
  const roles = useAppSelector((state) => state.extraField.role);
  const specific_user = useAppSelector((state) => state.user.specific_user);
  const [show, setShow] = useState(false);
  const active_options = [
    {
      label: "InActive",
      value: 0,
    },
    { label: "Active", value: 1 },
  ];
  useEffect(() => {
    httpClients
      .GET("/offices", true)
      .then((res: any) => {
        dispatch(set_office(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching offices!",
          status: "error",
        });
      });
    httpClients
      .GET("/designations", true)
      .then((res: any) => {
        dispatch(set_designation(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching designations!",
          status: "error",
        });
      });
    httpClients
      .GET("/roles", true)
      .then((res: any) => {
        dispatch(set_role(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching roles!",
          status: "error",
        });
      });
  }, [dispatch]);
  const input_change = (e: any) => {
    dispatch(setSpecificuser({ name: e.target.name, value: e.target.value }));
  };
  const select_change = (name: string, value: any) => {
    dispatch(setSpecificuser({ name, value }));
  };
  return (
    <ComponentWrapper
      icon={<BiUser />}
      form_submit_handler={props.form_handler}
      title={"प्रयोगकर्ता " + props.title}
      width="70%"
      action_btn={props.btn_name}
    >
      <Flex gap={8}>
        <FormControl isRequired>
          <FormLabel p={0} m={0}>
            Full Name
          </FormLabel>
          <InputGroup>
            <Input
              fontWeight="500"
              type="text"
              name="name"
              placeholder="Enter your full name"
              onChange={input_change}
              value={specific_user?.name}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel p={0} m={0}>
            Address
          </FormLabel>
          <InputGroup>
            <Input
              fontWeight="500"
              type="text"
              name="address"
              placeholder="Enter your address"
              onChange={input_change}
              value={specific_user?.address}
            />
          </InputGroup>
        </FormControl>
      </Flex>
      <Flex gap={8}>
        <FormControl isRequired>
          <FormLabel p={0} m={0}>
            Email Address
          </FormLabel>
          <InputGroup>
            <Input
              fontWeight="500"
              type="email"
              name="email"
              placeholder="Enter your email"
              onChange={input_change}
              value={specific_user?.email}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel p={0} m={0}>
            Phone Number
          </FormLabel>
          <InputGroup>
            <Input
              fontWeight="500"
              type="Number"
              name="phone_number"
              placeholder="98*******"
              onChange={input_change}
              value={specific_user?.phone_number}
            />
          </InputGroup>
        </FormControl>
      </Flex>
      <Flex gap={8}>
        <SelectInput
          label="Status"
          name="active_status"
          options={active_options}
          value={active_options.filter(
            (p: any) =>
              p.value.toString() === specific_user?.active_status.toString()
          )}
          on_change={(e: any) => select_change("active_status", e.value)}
          required
          placeholder="Select Status"
        />
        <SelectInput
          label="Office"
          required
          placeholder="Select Office"
          name="office_id"
          options={
            office &&
            office.map((p: any) => {
              return { label: p.office_name, value: p.id };
            })
          }
          value={
            office &&
            office
              .map((p: any) => {
                return { label: p.office_name, value: p.id };
              })
              ?.filter(
                (p: any) =>
                  p.value.toString() === specific_user?.office_id?.toString()
              )
          }
          on_change={(e: any) => select_change("office_id", e.value)}
        />
      </Flex>
      <Flex gap={8}>
        <SelectInput
          label="Designation"
          required
          placeholder="Select Designation"
          options={
            designation &&
            designation.map((p: any) => {
              return { label: p.designation, value: p.id };
            })
          }
          name="designation_id"
          value={
            designation &&
            designation
              .map((p: any) => {
                return { label: p.designation, value: p.id };
              })
              ?.filter(
                (p: any) =>
                  p.value.toString() ===
                  specific_user?.designation_id?.toString()
              )
          }
          on_change={(e: any) => select_change("designation_id", e.value)}
        />
        <SelectInput
          label="Role"
          required
          placeholder="Select Role"
          options={
            roles &&
            roles.map((p: any) => {
              return { label: p.role, value: p.id };
            })
          }
          name="roles"
          multi
          value={
            roles &&
            roles
              .map((p: any) => {
                return { label: p.role, value: p.id };
              })
              .filter((p: any) => specific_user?.roles.includes(p.value))
          }
          on_change={(e: any) =>
            select_change(
              "roles",
              e.map((p: any) => p.value)
            )
          }
        />
      </Flex>
      {props.password ? (
        <FormControl isRequired width="48%">
          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              fontWeight="500"
              type={show ? "text" : "password"}
              placeholder="*******************"
              className="auth_input"
              name="password"
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
      ) : (
        <></>
      )}
    </ComponentWrapper>
  );
};
export default UserLayout;
