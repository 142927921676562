import style from "./index.module.scss";
import Tabs from "../../../component/tab";
import IncidentData from "./incident_data";
import Health from "./heath";
import OtherInfo from "./other_info";
import UsefulDocs from "./useful_docs";
const CreateCaseModule = () => {
  const tabs = [
    {
      title: "घटनाको विवरण",
      content: <IncidentData />,
    },
    {
      title: "पिडितको स्वास्थ्य परिक्षण",
      content: <Health />,
    },
    {
      title: "अन्य जानकारी",
      content: <OtherInfo />,
    },

    {
      title: "आवश्यक कागजातहरु",
      content: <UsefulDocs />,
    },
  ];

  return (
    <div className={style.fields}>
      <div style={{ padding: "1.5rem" }}>
        <p className={style.medium_bold_font}>घटना दर्ता विवरण फाराम</p>
        <p className={style.light_grey_font}>
          अनुमति दिइएको फाइल प्रकारहरू: png, jpg, jpeg
        </p>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};
export default CreateCaseModule;
