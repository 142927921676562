import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import SelectInput from "../../../../component/input/input_select";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_hook";
import { useEffect } from "react";
import httpClients from "../../../../utils/httpClients";
import { set_specific_field } from "../../../../redux/case";
import TabButton from "../../../../component/button/tab_button";
import { check_tab, set_tab_id } from "../../../../redux/tabs";
import { setaffectedservices } from "../../../../redux/affected_services";
import { setaffectedvictims } from "../../../../redux/affected_victim";

const OtherInfo = () => {
  const dispatch = useAppDispatch();
  const victim = useAppSelector((state) => state.affectedvictims.data);
  const services = useAppSelector((state) => state.affectedservices.data);
  const data = useAppSelector((state) => state.case.data);
  useEffect(() => {
    httpClients.GET("affectedservices", true).then((res) => {
      dispatch(setaffectedservices(res.data.data));
    });
    httpClients.GET("affectedvictims", true).then((res) => {
      dispatch(setaffectedvictims(res.data.data));
    });
  }, [dispatch]);
  const onSubmit = (e: any) => {
    e.preventDefault();
    dispatch(set_tab_id(3));
    dispatch(check_tab(2));
  };
  const select_change = (name: string, value: any) => {
    dispatch(set_specific_field({ name, value }));
  };
  const changeHandler = (e: any) => {
    dispatch(
      set_specific_field({ name: e.target.name, value: e.target.value })
    );
  };
  return (
    <form onSubmit={onSubmit}>
      <Flex direction={"column"} gap={5} p={4}>
        <Flex gap={4}>
          <SelectInput
            required
            label="पिडित प्रभावितहरुलाई यहाँ आउन प्रेषण गर्ने"
            placeholder="कृपया छान्नुहोस"
            options={
              victim &&
              victim?.map((p: any) => {
                return { label: p.name, value: p.id };
              })
            }
            on_change={(e: any) => select_change("affected_victim_id", e.value)}
            value={
              victim &&
              victim
                ?.map((p: any) => {
                  return { label: p.name, value: p.id };
                })
                .filter(
                  (p: any) =>
                    p.value.toString() === data?.affected_victim_id?.toString()
                )
            }
            name="affected_victim_id"
          />
          <SelectInput
            required
            label="पिडित प्रभावितलाई प्रेषण गरिएको सेवा केन्द्र"
            placeholder="कृपया छान्नुहोस"
            options={
              services &&
              services?.map((p: any) => {
                return { label: p.name, value: p.id };
              })
            }
            on_change={(e: any) =>
              select_change("affected_service_center_id", e.value)
            }
            value={
              services &&
              services
                ?.map((p: any) => {
                  return { label: p.name, value: p.id };
                })
                .filter(
                  (p: any) =>
                    p.value.toString() ===
                    data?.affected_service_center_id?.toString()
                )
            }
            name="affected_service_center_id"
          />
        </Flex>
        <FormControl isRequired>
          <FormLabel>घटनाको पृष्ठभूमि</FormLabel>
          <InputGroup>
            <Textarea
              name="incident_background"
              rows={5}
              value={data?.incident_background}
              onChange={changeHandler}
            />
          </InputGroup>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>कैफियत</FormLabel>
          <InputGroup>
            <Textarea
              name="others"
              rows={5}
              value={data?.others}
              onChange={changeHandler}
            />
          </InputGroup>
        </FormControl>
      </Flex>
      <TabButton />
    </form>
  );
};
export default OtherInfo;
