import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../utils/httpClients";
import { DataTable } from "../../component/data_table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { deleteuser, setUser } from "../../redux/user";
import { set_modal } from "../../redux/extra_field";

const User = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);
  useEffect(() => {
      httpClients
        .GET("users", true)
        .then((res) => {
          dispatch(setUser(res.data.data));
          console.log(res.data.data);
        })
        .catch((err) => {
          toast({
            title: "Error occured while fetching users!",
            status: "error",
          });
        });
  }, [dispatch]);
  const delete_handler = async (delete_id: number) => {
    httpClients
      .DELETE("/users/" + delete_id, true)
      .then((res) => {
        console.log(res);
        dispatch(deleteuser(delete_id));
        dispatch(set_modal(false));
        toast({
          title: "User has been deleted successfully!",
          status: "success",
        });
      })
      .catch((err) => {
        dispatch(set_modal(false));
        toast({ title: "Error occured while deleting user!", status: "error" });
      });
  };
  const columns = [
    {
      header: "S.N.",
      accessorKey: "id",
    },
    {
      header: "Full name",
      accessorKey: "name",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Phone number",
      accessorKey: "phone_number",
    },
    {
      header: "Address",
      accessorKey: "address",
    },
    {
      header: "Status",
      accessorKey: "active_status",
    },
  ];
  return (
    <>
      <DataTable
        btn
        title="प्रयोगकर्ता"
        actions
        delete_handler={delete_handler}
        data={
          user &&
          user.map((p: any) => {
            return {
              id: p?.id,
              name: p?.name,
              address: p?.address,
              email: p?.email,
              phone_number: p?.phone_number,
              active_status: p?.active_status ? "Active" : "In Active",
            };
          })
        }
        columns={columns}
      />
    </>
  );
};
export default User;
