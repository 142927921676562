import { createSlice } from "@reduxjs/toolkit";
interface DistrictState {
  data: Array<Object>;
  specific_district: {
    district_en: string;
    district_np: string;
    province_id: number;
  };

  loading: boolean;
}

const initialState: DistrictState = {
  data: [],
  specific_district: {
    district_en: "",
    district_np: "",
    province_id: 0,
  },

  loading: false,
};

export const districtSlice = createSlice({
  name: "districtSlice",
  initialState,
  reducers: {
    set_loading_district: (state, action) => {
      state.loading = action.payload;
    },
    setDistrict: (state, action) => {
      state.data = action.payload;
    },
    addDistrict: (state, action) => {
      state.data = [...state.data, action.payload];
    },

    getSpecificDistrict: (state, action) => {
      state.specific_district = {
        ...state,
        district_en: action.payload.district_en,
        district_np: action.payload.district_np,
        province_id: action.payload.province_id,
      };
    },
    setSpecificDistrict: (state, action) => {
      state.specific_district = {
        ...state.specific_district,
        [action.payload.name]: action.payload.value,
      };
    },
    deleteDistrict: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setDistrict,
  addDistrict,
  deleteDistrict,
  getSpecificDistrict,
  setSpecificDistrict,
  set_loading_district,
} = districtSlice.actions;

export default districtSlice.reducer;
