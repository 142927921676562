import CaseForm from "./create";
import { useToast } from "@chakra-ui/react";
import { useAppDispatch } from "../../../hooks/redux_hook";
import { useParams } from "react-router";
import { useEffect } from "react";
import httpClients from "../../../utils/httpClients";
import { set_data, set_status } from "../../../redux/case";
const UpdateCaseForm = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const toast = useToast();
  useEffect(() => {
    dispatch(set_status('update'));
    httpClients
      .GET("gvs/" + id, true)
      .then((res) => {
        console.log(res.data.data);
        dispatch(set_data(res.data?.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching gvs data",
          status: "error",
        });
      });
  }, [dispatch]);
  return <CaseForm />;
};
export default UpdateCaseForm;
