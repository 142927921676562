import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import FieldSet from "../../../component/field_set";
import { useAppSelector } from "../../../hooks/redux_hook";
const ViewUserMain = () => {
  const user_data = useAppSelector((state) => state.extraField.loginuser_data);
  return (
    <div
      style={{
        width: "70%",
        margin: "0 auto",
        height: "100%",
      }}
    >
      <FieldSet title="आधारभूत जानकारी">
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              पूरा नाम
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user_data?.name} />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              ठेगाना
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user_data?.address} />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              भूमिका
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={user_data?.roles[0]?.role}
              />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              पद
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={user_data?.designation?.designation}
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              सक्रिय स्थिति
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={
                  user_data?.active_status
                    ? "सक्रिय स्थिति"
                    : "निष्क्रिय स्थिति"
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              इमेल
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={user_data?.email} />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              फोन नम्बर
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={user_data?.phone_number}
              />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              कार्यालय
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={user_data?.office?.office_name}
              />
            </InputGroup>
          </FormControl>
        </Flex>
      </FieldSet>
    </div>
  );
};
export default ViewUserMain;
