import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import ComponentWrapper from "../../../component/component_wrapper";
import SelectInput from "../../../component/input/input_select";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { useEffect } from "react";
import httpClients from "../../../utils/httpClients";
import {
  setSpecificpermission,
  setparentchildern,
} from "../../../redux/permission";

const PermissionLayout = (props: {
  title?: string;
  btn_name?: string;
  form_handler?: any;
}) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: any) => state.permission.parent_children);
  const specific_data = useAppSelector(
    (state: any) => state.permission.specific_permission
  );
  useEffect(() => {
    if (data.length === 0) {
      httpClients
        .GET("/permissions/parent/children", true)
        .then((res) => {
          dispatch(
            setparentchildern([
              { permission_name: "Parent", id: 0 },
              ...res.data.data[0].childrens,
            ])
          );
        })
        .catch((err) => console.log(err));
    }
  }, [dispatch]);
  const input_change = (e: any) => {
    dispatch(
      setSpecificpermission({ name: e.target.name, value: e.target.value })
    );
  };
  const select_change = (name: string, value: any) => {
    dispatch(setSpecificpermission({ name, value }));
  };
  return (
    <ComponentWrapper
      title={"अनुमति " + props.title}
      action_btn={props.btn_name}
      form_submit_handler={props.form_handler}
      width="50%"
      icon={<AiOutlineUserAdd />}
    >
      <FormControl isRequired>
        <FormLabel p={0} m={0}>
          Permission Name
        </FormLabel>
        <InputGroup>
          <Input
            fontWeight="500"
            type="text"
            name="permission_name"
            placeholder="permission name"
            value={specific_data?.permission_name}
            onChange={input_change}
          />
        </InputGroup>
      </FormControl>
      <FormControl isRequired>
        <FormLabel p={0} m={0}>
          Guard Name
        </FormLabel>
        <InputGroup>
          <Input
            fontWeight="500"
            type="text"
            name="guard_name"
            placeholder="guard name"
            value={specific_data?.guard_name}
            onChange={input_change}
          />
        </InputGroup>
      </FormControl>
      <SelectInput
        options={data.map((p: any) => {
          return {
            label: p.permission_name,
            value: p.id,
          };
        })}
        value={data
          .map((p: any) => {
            return {
              label: p.permission_name,
              value: p.id,
            };
          })
          .filter(
            (p: any) =>
              p.value.toString() === specific_data?.parent_id.toString()
          )}
        label="Parent Permission"
        name="parent_id"
        placeholder="Select Permission"
        on_change={(e: any) => select_change("parent_id", e.value)}
        required
      />
    </ComponentWrapper>
  );
};
export default PermissionLayout;
