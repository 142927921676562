import { createSlice } from "@reduxjs/toolkit";
interface GenderState {
  data: Array<Object>;
  name:string
}

const initialState: GenderState = {
  data: [],
  name:""
};

export const genderSlice = createSlice({
  name: "genderSlice",
  initialState,
  reducers: {
    setgender: (state, action) => {
      state.data = action.payload;
    },
    getspecificgender: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setgender, getspecificgender } =
  genderSlice.actions;

export default genderSlice.reducer;
