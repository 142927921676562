import { Stack, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../utils/httpClients";
import { DataTable } from "../../component/data_table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_modal } from "../../redux/extra_field";
import { setgender } from "../../redux/gender";

const Gender = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const gender = useAppSelector((state) => state.gender.data);
  useEffect(() => {
    httpClients
      .GET("genders", true)
      .then((res) => {
        dispatch(setgender(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching genders",
          status: "error",
        });
      });
  }, [dispatch]);
  const columns = [
    {
      header: "क्र.स.",
      accessorKey: "id",
    },
    {
      header: "लिङ्ग",
      accessorKey: "name",
    },
  ];
  const deleteRole = async (delete_id: number) => {
    if (delete_id !== undefined && delete_id !== null) {
      httpClients
        .DELETE("genders/" + delete_id, true)
        .then(async (res) => {
          toast({
            title: "Gender has been deleted successfully!",
            status: "success",
          });
          dispatch(set_modal(false));
          await httpClients.GET("genders", true).then((res) => {
            dispatch(setgender(res.data.data));
          });
        })
        .catch((err) => {
          toast({
            title: "Error occured while deleting gender!",
            status: "error",
          });
          dispatch(set_modal(false));
        });
    }
  };
  return (
    <>
      <DataTable
        btn
        title="लिङ्ग"
        actions
        no_view
        nep
        data={
          gender &&
          gender.map((p: any) => {
            return {
              id: p?.id,
              name: p?.name,
            };
          })
        }
        columns={columns}
        delete_handler={deleteRole}
      />
    </>
  );
};
export default Gender;
