import { createSlice } from "@reduxjs/toolkit";
interface ExtraState {
  designation: any;
  office: any;
  role: any;
  modal?: boolean | undefined;
  provinces: any;
  district: any;
  llevel: any;
  loginuser_data: any;
  change_password: boolean;
  description_modal: boolean;
  district_fields: any;
  llevel_fields: any;
  designation_fields: any;
}

const initialState: ExtraState = {
  designation: undefined,
  office: undefined,
  role: undefined,
  modal: false,
  provinces: undefined,
  district: undefined,
  llevel: undefined,
  loginuser_data: undefined,
  change_password: false,
  description_modal: false,
  district_fields: undefined,
  llevel_fields: undefined,
  designation_fields: undefined,
};

export const extraSlice = createSlice({
  name: "extraSlice",
  initialState,
  reducers: {
    set_designation: (state, action) => {
      state.designation = action.payload;
    },
    set_office: (state, action) => {
      state.office = action.payload;
    },
    set_role: (state, action) => {
      state.role = action.payload;
    },
    set_modal: (state, action) => {
      state.modal = action.payload;
    },
    set_province: (state, action) => {
      state.provinces = action.payload;
    },
    get_district_fields: (state, action) => {
      state.district_fields = { ...action.payload };
    },
    set_district_fields: (state, action) => {
      state.district_fields = {
        ...state.district_fields,
        [action.payload.name]: action.payload.value,
      };
    },
    get_llevel_fields: (state, action) => {
      state.llevel_fields = { ...action.payload };
    },
    set_llevel_fields: (state, action) => {
      state.llevel_fields = {
        ...state.llevel_fields,
        [action.payload.name]: action.payload.value,
      };
    },
    set_designation_fields: (state, action) => {
      state.designation_fields = action.payload;
    },
    set_district: (state, action) => {
      state.district = action.payload;
    },
    set_llevel: (state, action) => {
      state.llevel = action.payload;
    },
    set_loginuser_data: (state, action) => {
      state.loginuser_data = action.payload;
    },
    set_change_password: (state) => {
      state.change_password = !state.change_password;
    },
    set_description_modal: (state) => {
      state.description_modal = !state.description_modal;
    },
  },
});

export const {
  set_designation,
  set_office,
  set_role,
  set_modal,
  set_district,
  set_province,
  set_llevel,
  set_loginuser_data,
  set_change_password,
  set_description_modal,
  get_district_fields,
  get_llevel_fields,
  set_district_fields,
  set_llevel_fields,
  set_designation_fields,
} = extraSlice.actions;

export default extraSlice.reducer;
