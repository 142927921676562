import httpClients from "../utils/httpClients";

export const reset_case = (
  fiscal_year?: number,
  province_id?: number,
  district_id?: number,
  llevel_id?: number
) => {
  return httpClients.GET(
    `gvs?fiscal_year_id=${fiscal_year}${
      province_id ? "&" : "?"
    }province_id=${province_id}${
      province_id ? "&" : "?"
    }district_id=${district_id}${
      province_id ? "&" : "?"
    }llevel_id=${llevel_id}`,
    true
  );
};
