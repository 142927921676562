import { useEffect, useState } from "react";
import httpClients from "../../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { setSpecificrole, setrolespermission } from "../../../redux/role";
import {
  FormControl,
  Input,
  InputGroup,
  useToast,
  FormLabel,
  Text,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import ComponentWrapper from "../../../component/component_wrapper";
import { GrUserAdmin } from "react-icons/gr";

const RoleLayout = (props: {
  title?: string;
  form_handler?: any;
  btn_name?: string;
  data?: any;
}) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const role = useAppSelector((state: any) => state.role.specific_role);
  const data = useAppSelector((state: any) => state.role.roles_permissions);
  useEffect(() => {
    httpClients
      .GET("/permissions/parent/children", true)
      .then((res) => {
        dispatch(setrolespermission(res.data.data));
        console.log(res);
      })
      .catch((err) => {
        toast({
          title: "error occured while fetching permissions",
          status: "error",
        });
      });
  }, [dispatch, toast]);
  const changeHandler = (name: string, value: string) => {
    dispatch(setSpecificrole({ name, value }));
  };
  const select_all = (e: any) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      dispatch(
        setSpecificrole({
          name: "permissions",
          value: data.map((p: any) => [
            p.id,
            ...p.childrens.map((p: any) => p.id),
          ])[0],
        })
      );
    }
    if (!e.target.checked) {
      dispatch(
        setSpecificrole({
          name: "permissions",
          value: [],
        })
      );
    }
  };
  const select_specific_cat = (e: any, data: any) => {
    if (e.target.checked) {
      dispatch(
        setSpecificrole({
          name: "permissions",
          value: [...role.permissions,...data],
        })
      );
    }
    if (!e.target.checked) {
      dispatch(
        setSpecificrole({
          name: "permissions",
          value: role.permissions.filter((p: any) => !data.includes(p)),
        })
      );
    }
  };
  const select_specific = (e: any, id: any) => {
    if (e.target.checked) {
      dispatch(
        setSpecificrole({
          name: "permissions",
          value: [...role.permissions, id],
        })
      );
    }
    if (!e.target.checked) {
      dispatch(
        setSpecificrole({
          name: "permissions",
          value: role.permissions.filter(
            (p: any) => p.toString() !== id.toString()
          ),
        })
      );
    }
  };
  return (
    <ComponentWrapper
      title={"भूमिका " + props.title}
      form_submit_handler={props.form_handler}
      width="60%"
      action_btn={props.btn_name}
      icon={<GrUserAdmin />}
    >
      <FormControl isRequired>
        <FormLabel>भूमिका</FormLabel>
        <InputGroup>
          <Input
            fontWeight="500"
            type="text"
            name="role"
            placeholder="भूमिका नाम"
            value={role.role}
            onChange={(e: any) => changeHandler("role", e.target.value)}
          />
        </InputGroup>
      </FormControl>
      <Text className="bold" style={{ fontSize: "1rem" }}>
        अनुमतिहरू
      </Text>
      <Checkbox onChange={select_all}>Select All</Checkbox>
      {Object.keys(data).length > 0 &&
        data?.map((r: any) => (
          <>
            <Flex alignItems={"flex-start"} gap={"3rem"}>
              <Flex gap={"5px"} minWidth={"max-content"} alignItems={"center"}>
                <Checkbox
                  type="checkbox"
                  id="checked"
                  onChange={(e: any) =>
                    select_specific_cat(e, [
                      r.id,
                      ...r.childrens.map((p: any) => p.id),
                    ])
                  }
                  isChecked={role && role.permissions?.includes(r.id)}
                >
                  {r.permission_name}
                </Checkbox>
              </Flex>
              <Flex direction={"column"} flexWrap={"wrap"} gap={"1rem"}>
                {r.childrens.map((p: any) => (
                  <Flex alignItems={"center"} gap="5px">
                    <Checkbox
                      type="checkbox"
                      id="checked"
                      className={`checked${r.permission_name
                        .toLowerCase()
                        .split(" ")
                        .join("")}`}
                      value={p.id}
                      onChange={(e: any) => select_specific(e, p.id)}
                      isChecked={role && role?.permissions?.includes(p.id)}
                    >
                      {p.permission_name}
                    </Checkbox>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </>
        ))}
    </ComponentWrapper>
  );
};
export default RoleLayout;
