import { useNavigate, useParams } from "react-router";
import { CiCircleRemove } from "react-icons/ci";
import {
  Box,
  Button,
  SkeletonCircle,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import Mukta from "./font/Mukta-Medium.ttf";
import { useEffect, useState } from "react";
import GovLogo from "../assets/mofoga.png";
import NepaliDate from "nepali-date-converter";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  View,
} from "@react-pdf/renderer";
import httpClients from "../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../hooks/redux_hook";
import { set_view_case_data } from "../redux/case";
import { changeIndexToNepali } from "../hooks/number_translation";
// Create styles

const Template = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [user, setUser] = useState();
  const [data, setData] = useState();
  const date = new NepaliDate().getBS();
  const navigate = useNavigate();
  const gvs = useAppSelector((state) => state.case.view_case_data);
  const optionsHandler = (value) => {
    if (value === "1") {
      return "भएको";
    } else if (value === "2") {
      return "नभएको";
    } else {
      return "आवश्यक नरहेको";
    }
  };
  useEffect(() => {
    httpClients
      .GET("/users/" + localStorage.getItem("user_id"), true)
      .then((res) => {
        setUser(res.data.data);
        console.log(res.data.data);
        httpClients
          .GET("/offices/" + res.data.data?.office?.id, true)
          .then((res) => {
            setData(res.data.data);
          })
          .catch((err) => {
            toast({
              title: "Error occured: Please re-login!",
              status: "error",
            });
          });
      })
      .catch((err) => {
        toast({ title: "Error occured: Please re-login!", status: "error" });
      });
    httpClients
      .GET("gvs/" + id, true)
      .then((res) => {
        console.log(res.data);
        dispatch(set_view_case_data(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "हामी केस फारमको लागि आवश्यक डेटा प्राप्त गर्न सक्दैनौं",
          status: "error",
        });
      });
  }, [dispatch, id, toast]);
  Font.register({
    family: "Mukta",
    src: Mukta,
  });
  const styles = StyleSheet.create({
    body: {
      minHeight: "100%",
      width: "100%",
      fontFamily: "Mukta",
      padding: 30,
    },
    // title: {
    //   fontSize: 24,
    //   textAlign: "center",
    //   fontFamily: "Mukta",
    // },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
      fontFamily: "Mukta",
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: "Mukta",
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Mukta",
    },
    image: {
      height: 60,
      width: 70,
    },
    header: {
      textAlign: "center",
      fontFamily: "Mukta",
      fontSize: 10,
    },
    header_2: {
      textAlign: "center",
      fontFamily: "Mukta",
      fontSize: 20,
    },
    header_3: {
      textAlign: "center",
      fontFamily: "Mukta",
      fontSize: 10,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
      fontFamily: "Mukta",
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    tableCol: {
      width: "33.33%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "left",
    },
    tableCol2: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "left",
      width: "100%",
    },
    tableCol3: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      textAlign: "left",
      width: "33.3%",
    },
    tableCol5: {
      width: "10%",
      borderStyle: "solid",
      borderWidth: 1,
      borderTopWidth: 0,
      textAlign: "left",
    },
    tableCol6: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "left",
      width: "15%",
    },
    tableCol7: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "left",
      width: "25%",
    },
    tableCol8: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "left",
      width: "50%",
    },
    tableCol9: {
      width: "10%",
      borderStyle: "solid",
      borderWidth: 1,
      textAlign: "left",
    },
    tableCol10: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      textAlign: "left",
      width: "15%",
    },
    tableCol11: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      textAlign: "left",
      width: "25%",
    },
    tableCol12: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      textAlign: "left",
      width: "50%",
    },
    cell: {
      marginTop: 5,
      marginLeft: 5,
      marginRight: 5,
      fontSize: 10,
    },
    cell2: {
      marginTop: 5,
      marginLeft: 5,
      marginRight: 5,
      fontSize: 10,
      marginBottom: 17,
    },
    title: {
      fontSize: 10,
      marginTop: 10,
    },
    header_layout: {
      display: "flex",
      gap: 16,
      position: "relative",
      textAlign: "center",
      wordSpacing: "5px",
      flexDirection: "row",
    },
    header_layout_2: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      position: "absolute",
      top: 0,
    },
    small_font: {
      fontSize: 9,
      textAlign: "center",
    },
    footer: {
      borderTop: "1px solid #000",
    },
    header_bottom: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-bottom",
      flexDirection: "row",
      marginTop: 5,
      marginBottom: 5,
    },
    header_bottom2: {
      display: "flex",
      flexDirection: "column",
    },
    subject: {
      fontSize: 12,
      textDecoration: "underline",
      textAlign: "center",
      marginBottom: 5,
    },
    sign: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      marginTop: 25,
    },
    table2: {
      display: "flex",
      flexDirection: "row",
    },
    spacebetween: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100%",
    },
  });
  return (
    <Box height={"100%"}>
      <Button
        colorScheme="red"
        variant={"outline"}
        bgColor={"white"}
        onClick={() => navigate(-1)}
        leftIcon={<CiCircleRemove />}
        marginBottom={4}
      >
        Exit
      </Button>
      {data && user && gvs ? (
        <PDFViewer showToolbar={true} width={"100%"} height={1000}>
          <Document>
            <Page size={"A4"} style={styles.body} wrap>
              <View style={styles.spacebetween}>
                <View>
                  <View fixed>
                    <View style={styles.header_layout}>
                      <Image src={GovLogo} alt="govlogo" style={styles.image} />
                      <View style={styles.header_layout_2}>
                        <Text style={styles.header} fixed>
                          श्री {data.locallevel?.lgname_np}
                        </Text>
                        <Text style={styles.header_2} fixed>
                          {data.locallevel?.lgname_np
                            .split(" ")
                            .includes("नगरपालिका")
                            ? "नगर कार्यपालिका"
                            : "गाऊँ कार्यपालिका"}
                          को कार्यालय
                        </Text>
                        <Text style={styles.header} fixed>
                          {data && data?.office_address}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.header_bottom}>
                      <View style={styles.small_font}>
                        <Text>केस नं.:</Text>
                        <Text>पत्र संख्या:</Text>
                      </View>
                      <Text style={styles.small_font}>
                        {"मिति: " +
                          changeIndexToNepali(date.year) +
                          "/" +
                          changeIndexToNepali(date.month) +
                          "/" +
                          changeIndexToNepali(date.day)}
                      </Text>
                    </View>
                  </View>
                  <Text style={styles.subject}>
                    विषय: लैङ्गिक हिंसा दर्ता फाराम
                  </Text>
                  <Text style={styles.title}>घटनाको विवरण</Text>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          आर्थिक वर्ष:{" "}
                          {changeIndexToNepali(gvs?.fiscal_year?.name)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          पीडितको नाम: {gvs?.afflicted_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          घटना दर्ता मिति:{" "}
                          {changeIndexToNepali(gvs?.incident_report_date)}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          उमेर: {changeIndexToNepali(gvs?.age)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>ठेगाना: {gvs?.address}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          जातजाति /जनजाति: {gvs?.caste_tribe?.name}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          लिङ्ग: {gvs?.gender?.name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          अपाङ्गता भएका व्यक्ति: {gvs?.disablities_people?.name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          हिंसाको प्रकार: {gvs?.voilence_type?.name}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol2}>
                        <Text style={styles.cell}>
                          घटनाको पृष्ठभूमिः {gvs?.incident_background}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol2}>
                        <Text style={styles.cell}>कैफियत: {gvs?.others}</Text>
                      </View>
                    </View>
                  </View>
                  <Text style={styles.title}>
                    पिडितको स्वास्थ्य परिक्षणको विवरण
                  </Text>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell2}>
                          शारीरिक परिषण: {optionsHandler(data?.physical_exam)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          विधि-विज्ञान तथा चिकित्साजन्य कानूनी परीक्षणति:{" "}
                          {optionsHandler(data?.fmedical_legal_test)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          एच.आई.भी.स्वेच्छिक परिषण तथा परामर्श सेवा:{" "}
                          {optionsHandler(data?.hiv_vol_testing)}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          गर्भ परीक्षण: {optionsHandler(data?.pregnancy_test)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          घाउ चोटपटकको उपचार:{" "}
                          {optionsHandler(data?.wounds_treatment)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          आकस्मिक गर्भ निरोधक
                          <br /> सेवा:{" "}
                          {optionsHandler(
                            data?.emergency_contraceptive_service
                          )}{" "}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          यौन रोगको <br />
                          उपचार:{" "}
                          {optionsHandler(data?.sexual_disease_treatment)}{" "}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          सुरक्षित गर्भपतन सेवा:{" "}
                          {optionsHandler(data?.safe_abortion_service)}{" "}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          मानसिक रोगको
                          <br /> उपचार:{" "}
                          {optionsHandler(data?.mental_disease_treatment)}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          मनोसामाजिक परामर्श <br />
                          सेवा: {optionsHandler(data?.psychosocial_con_service)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.cell}>
                          अन्य उपचार सेवा:{" "}
                          {optionsHandler(data?.other_treatment_service)}
                        </Text>
                      </View>
                      <View style={styles.tableCol3}></View>
                    </View>
                  </View>
                  <Text style={styles.title}>हाल केसको अवस्था</Text>
                  <View style={styles.table2}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol9}>
                        <Text style={styles.cell}>क्र.स.</Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text style={styles.cell}>मिति</Text>
                      </View>
                      <View style={styles.tableCol11}>
                        <Text style={styles.cell}>
                          प्रेषण गरिएको सेवा केन्द्र
                        </Text>
                      </View>
                      <View style={styles.tableCol12}>
                        <Text style={styles.cell}>कैफीयत</Text>
                      </View>
                    </View>
                  </View>
                  <View>
                    <View style={styles.table2}>
                      <View style={styles.tableCol5}>
                        <Text style={styles.cell}>
                          {changeIndexToNepali(1)}
                        </Text>
                      </View>
                      <View style={styles.tableCol6}>
                        <Text style={styles.cell}>
                          {changeIndexToNepali(gvs?.incident_report_date)}
                        </Text>
                      </View>
                      <View style={styles.tableCol7}>
                        <Text style={styles.cell}>
                          {gvs?.affected_service_center?.name}
                        </Text>
                      </View>
                      <View style={styles.tableCol8}>
                        <Text style={styles.cell}>{gvs?.others}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.sign}>
                    <Text style={styles.small_font}>
                      .........................................
                    </Text>
                    <Text style={styles.small_font}>
                      {user.designation.designation}
                    </Text>
                  </View>
                </View>
                <View style={styles.footer} fixed>
                  <Text style={styles.small_font}>
                    विरेन्द्रनगर, सुर्खेत, नेपाल, फोन नॱ. ४४६००४, फ्याक्स:
                    ५५५-१२३-४५६७
                  </Text>
                  <Text style={styles.small_font}>
                    इमेल: gbv@nepal.com, वेभसाइट: gbv.com.np
                  </Text>
                </View>
              </View>
              {/* <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              /> */}
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <Box padding="6" boxShadow="lg" bg="white">
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        </Box>
      )}
    </Box>
  );
};
export default Template;
