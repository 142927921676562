import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
};
export function Graph1(props:{data:object}) {
  const keysArray = props.data && Object.keys(props.data);
  const valuesArray = props.data && Object.values(props.data);
  const data = {
    labels:keysArray || [],
    datasets: [
      {
        label: "संख्या",
        data: valuesArray || [],
        backgroundColor: "#2b6cb0",
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
