import { createSlice } from "@reduxjs/toolkit";
interface Disability {
  data: Array<Object>;
  name: String;
}

const initialState: Disability = {
  data: [],
  name: ""
};

export const disabilitySlice = createSlice({
  name: "disabilitySlice",
  initialState,
  reducers: {
    setdisability: (state, action) => {
      state.data = action.payload;
    },
    getspecificdisability: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setdisability, getspecificdisability } =
  disabilitySlice.actions;

export default disabilitySlice.reducer;
