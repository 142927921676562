import { Routes, Route } from "react-router-dom";
import AdminPanel from "../pages/admin";
import Login from "../pages/login";
import { useAppSelector } from "../hooks/redux_hook";
import ErrorPage from "../pages/404_page";
import User from "../pages/user";
import ViewUser from "../pages/user/controller/view";
import UpdateUser from "../pages/user/controller/update";
import UserCreate from "../pages/user/controller/create";
import Role from "../pages/role";
import Permission from "../pages/permission";
import ViewPermission from "../pages/permission/controller/view";
import ViewRole from "../pages/role/controller/view";
import CaseReportModule from "../pages/case_report_module";
import CreateCaseModule from "../pages/case_report_module/controller/index";
import RoleCreate from "../pages/role/controller/create";
import RoleUpdate from "../pages/role/controller/updates";
import UpdatePermission from "../pages/permission/controller/update";
import CreatePermission from "../pages/permission/controller/create";
import Report from "../pages/case_report_module/report";
import ViewCase from "../pages/case_report_module/case/view";
import ViewUserMain from "../pages/user/view_user";
import UpdateCaseForm from "../pages/case_report_module/controller/update";
import Dashboard from "../pages/dashboard";
import Gender from "../pages/gender";
import Caste from "../pages/caste";
import Voilence from "../pages/voilence";
import Disabilities from "../pages/disability";
import AffectedServices from "../pages/affected_services";
import AffectedVictims from "../pages/affected_victims";

import CreateGender from "../pages/gender/component/create";
import UpdateGender from "../pages/gender/component/update";
import CreateDisability from "../pages/disability/component/create";
import UpdateDisability from "../pages/disability/component/update";
import CreateCaste from "../pages/caste/component/create";
import UpdateCaste from "../pages/caste/component/update";
import UpdateVoilence from "../pages/voilence/component/update";
import CreateVoilence from "../pages/voilence/component/create";
import CreateAffectedServices from "../pages/affected_services/component/create";
import UpdateAffectedServices from "../pages/affected_services/component/update";
import CreateAffectedVictims from "../pages/affected_victims/component/create";
import UpdateAffectedVictims from "../pages/affected_victims/component/update";
import FiscalYear from "../pages/fiscal_year";
import UpdateFiscalYear from "../pages/fiscal_year/component/update";
import CreateFiscalYear from "../pages/fiscal_year/component/create";
import Office from "../pages/office";
import OfficeType from "../pages/office_type";
import OfficeCreate from "../pages/office/controller/create";
import UpdateOffice from "../pages/office/controller/update";
import UpdateOfficeLayout from "../pages/office_type/component/update";
import CreateOfficeType from "../pages/office_type/component/create";
import Province from "../pages/federal_structure/province";
import District from "../pages/federal_structure/district";
import LocalLevel from "../pages/federal_structure/llevel";
import LocalLevelUpdate from "../pages/federal_structure/llevel/update";
import DistrictUpdate from "../pages/federal_structure/district/update";
import Designation from "../pages/designation";
import CreateDesignation from "../pages/designation/component/create";
import DesignationUpdate from "../pages/designation/component/update";
import ViewOffice from "../pages/office/controller/view";
const AllRoutes = () => {
  const isLoggedIn = useAppSelector((state) => state.login.isLoggedIn);
  const access = useAppSelector((state) => state.login.access);
  return (
    <>
      {isLoggedIn && (
        <AdminPanel>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            {true && (
              <>
                <Route path="user_configuration/user" element={<User />} />
                <Route
                  path="user_configuration/user/create"
                  element={<UserCreate />}
                />
                <Route
                  path="user_configuration/user/update/:id"
                  element={<UpdateUser />}
                />
                <Route
                  path="user_configuration/user/view/:id"
                  element={<ViewUser />}
                />
              </>
            )}
            {true && (
              <>
                <Route path="user_configuration/role" element={<Role />} />
                <Route
                  path="user_configuration/role/view/:id"
                  element={<ViewRole />}
                />
                <Route
                  path="user_configuration/role/create"
                  element={<RoleCreate />}
                />
                <Route
                  path="user_configuration/role/update/:id"
                  element={<RoleUpdate />}
                />
              </>
            )}
            {true && (
              <>
                <Route
                  path="user_configuration/permission"
                  element={<Permission />}
                />
                <Route
                  path="user_configuration/permission/create"
                  element={<CreatePermission />}
                />
                <Route
                  path="user_configuration/permission/view/:id"
                  element={<ViewPermission />}
                />
                <Route
                  path="user_configuration/permission/update/:id"
                  element={<UpdatePermission />}
                />
              </>
            )}
            <Route path="/federal_sturcture/province" element={<Province />} />
            <Route path="/federal_sturcture/district" element={<District />} />
            <Route path="/federal_sturcture/llevel" element={<LocalLevel />} />
            <Route
              path="/user_configuration/designation"
              element={<Designation />}
            />
            <Route
              path="/user_configuration/designation/create"
              element={<CreateDesignation />}
            />
            <Route
              path="/user_configuration/designation/update/:id"
              element={<DesignationUpdate />}
            />
            <Route
              path="/federal_sturcture/llevel/update/:id"
              element={<LocalLevelUpdate />}
            />
            <Route
              path="/federal_sturcture/district/update/:id"
              element={<DistrictUpdate />}
            />
            <Route path="/system_configuration/office" element={<Office />} />
            <Route
              path="/system_configuration/office/create"
              element={<OfficeCreate />}
            />
            <Route
              path="/system_configuration/office/update/:id"
              element={<UpdateOffice />}
            />
            <Route
              path="/system_configuration/office/view/:id"
              element={<ViewOffice />}
            />
            <Route
              path="/system_configuration/office_type"
              element={<OfficeType />}
            />
            <Route
              path="/system_configuration/office_type/create"
              element={<CreateOfficeType />}
            />
            <Route
              path="/system_configuration/office_type/update/:id"
              element={<UpdateOfficeLayout />}
            />
            <Route
              path="/system_configuration/fiscal_year"
              element={<FiscalYear />}
            />
            <Route
              path="/system_configuration/fiscal_year/create"
              element={<CreateFiscalYear />}
            />
            <Route
              path="/system_configuration/fiscal_year/update/:id"
              element={<UpdateFiscalYear />}
            />
            <Route path="system_configuration/genders" element={<Gender />} />
            <Route
              path="system_configuration/genders/create"
              element={<CreateGender />}
            />
            <Route
              path="system_configuration/genders/update/:id"
              element={<UpdateGender />}
            />
            <Route
              path="system_configuration/caste_tribes"
              element={<Caste />}
            />
            <Route
              path="system_configuration/caste_tribes/create"
              element={<CreateCaste />}
            />
            <Route
              path="system_configuration/caste_tribes/update/:id"
              element={<UpdateCaste />}
            />

            <Route
              path="system_configuration/voilence_types"
              element={<Voilence />}
            />
            <Route
              path="system_configuration/voilence_types/create"
              element={<CreateVoilence />}
            />
            <Route
              path="system_configuration/voilence_types/update/:id"
              element={<UpdateVoilence />}
            />

            <Route
              path="system_configuration/disabilities"
              element={<Disabilities />}
            />
            <Route
              path="system_configuration/disabilities/create"
              element={<CreateDisability />}
            />
            <Route
              path="system_configuration/disabilities/update/:id"
              element={<UpdateDisability />}
            />

            <Route
              path="system_configuration/affected_services"
              element={<AffectedServices />}
            />
            <Route
              path="system_configuration/affected_services/create"
              element={<CreateAffectedServices />}
            />
            <Route
              path="system_configuration/affected_services/update/:id"
              element={<UpdateAffectedServices />}
            />

            <Route
              path="system_configuration/affected_victims"
              element={<AffectedVictims />}
            />
            <Route
              path="system_configuration/affected_victims/create"
              element={<CreateAffectedVictims />}
            />
            <Route
              path="system_configuration/affected_victims/update/:id"
              element={<UpdateAffectedVictims />}
            />

            <Route path="/case_report/case" element={<CaseReportModule />} />
            <Route path="/case_report/case/view/:id" element={<ViewCase />} />
            <Route
              path="case_report/case/update/:id"
              element={<UpdateCaseForm />}
            />
            <Route
              path="/case_report/case/create"
              element={<CreateCaseModule />}
            />
            <Route path="/case_report/report" element={<Report />} />
            <Route path="/profile" element={<ViewUserMain />} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
        </AdminPanel>
      )}
      {!isLoggedIn && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      )}
    </>
  );
};

export default AllRoutes;
