import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { getspecificvictims, setaffectedvictims } from "../../../redux/affected_victim";
import AffectedVictimsLayout from ".";
const CreateAffectedVictims = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.affectedvictims.name);
  React.useEffect(() => {
    dispatch(getspecificvictims(""));
  }, [dispatch]);
  const createcaste = (e: any) => {
    e.preventDefault();
    httpClients
      .POST("affectedvictims", {name}, true)
      .then((res) => {
        toast({
          title: "Affected victims has been created!",
          status: "success",
        });
        httpClients.GET("affectedvictims", true).then((res) => {
          dispatch(setaffectedvictims(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating affected victims!",
          status: "error",
        });
      });
  };
  return (
    <AffectedVictimsLayout
      form_handler={createcaste}
      btn_name="थप्नुहोस्"
      title="थप्नुहोस्"
    />
  );
};
export default CreateAffectedVictims;
