import React from "react";
import { useAppDispatch } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router";
import {
  setSpecificofficetype,
  set_loading_officetype,
  setofficetype,
} from "../../../redux/office_type";
import OfficeTypeLayout from ".";
const UpdateOfficeLayout = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  React.useEffect(() => {
    httpClients
      .GET("/officetypes/" + id, true)
      .then((res) => {
        console.log(res.data.data.office_type);
        dispatch(setSpecificofficetype(res.data.data.office_type));
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error occured while fetching office type!",
          status: "error",
        });
      });
  }, [id, dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);

    dispatch(set_loading_officetype(true));
    httpClients
      .PUT("/officetypes/" + id, { office_type: form.get("office_type") }, true)
      .then((res) => {
        httpClients.GET("/officetypes", true).then((res: any) => {
          console.log(res);
          dispatch(set_loading_officetype(false));
          dispatch(setofficetype(res.data.data));
        });
        toast({ title: "Office type has been updated", status: "success" });
      })
      .catch((err) => {
        console.log(err);
        dispatch(set_loading_officetype(false));
        toast({
          title: "Error occured while updating office type!",
          status: "error",
        });
      });
  };
  return (
    <OfficeTypeLayout
      form_handler={form_handler}
      btn_name="सम्पादन"
      title="सम्पादन"
    />
  );
};
export default UpdateOfficeLayout;
