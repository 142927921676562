import {
  Flex,
  InputGroup,
  useToast,
  FormControl,
  Input,
  FormLabel,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import httpClients from "../../../utils/httpClients";
import { useParams } from "react-router";
import ViewWrapper from "../../../component/component_wrapper/view_wrapper";

const ViewOffice = () => {
  const toast = useToast();
  const { id } = useParams();
  const [office, setOffice] = useState<any>(null);
  useEffect(() => {
    httpClients
      .GET("offices/" + id, true)
      .then((res) => {
        setOffice(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error occured while fetching office",
          status: "error",
        });
      });
  }, [id, toast]);
  return (
    <ViewWrapper width="70%" title="कार्यालय विवरण">
      <Flex direction={"column"} gap={2}>
      <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
            प्रदेश
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={office?.province?.province_np} />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
            जिल्ला
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                name="email"
                value={office?.district?.district_np}
              />
            </InputGroup>
          </FormControl>
        </Flex>{" "}
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
            स्थानीय तह
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={office?.locallevel?.lgname_np} />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
            कार्यालय प्रकार
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                name="email"
                value={office?.office_type?.office_type}
              />
            </InputGroup>
          </FormControl>
        </Flex>{" "}
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              कार्यालय नाम / विभाग
            </FormLabel>
            <InputGroup>
              <Input fontWeight="500" type="text" value={office?.office_name} />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              कार्यालय ठेगाना
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                name="email"
                value={office?.office_address}
              />
            </InputGroup>
          </FormControl>
        </Flex>{" "}
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              कार्यालयको इमेल
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={office?.office_email}
              />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
              कार्यालयको फोन नम्बर
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={office?.office_phone}
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
            कार्यालयको अक्षांश
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={office?.latitude}
              />
            </InputGroup>
          </FormControl>
          <FormControl isReadOnly>
            <FormLabel p={0} m={0}>
            कार्यालयको देशान्तर
            </FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                value={office?.longitude}
              />
            </InputGroup>
          </FormControl>
        </Flex>
      </Flex>
    </ViewWrapper>
  );
};
export default ViewOffice;
