import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";

import AffectedVictimsLayout from ".";
import { getspecificvictims, setaffectedvictims } from "../../../redux/affected_victim";
const UpdateAffectedVictims = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const name = useAppSelector((state) => state.affectedvictims.name);
  React.useEffect(() => {
    httpClients
      .GET("affectedvictims/" + id, true)
      .then((res) => {
        console.log(res.data.data);
        dispatch(getspecificvictims(res.data.data.name));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching affected services data!",
          status: "error",
        });
      });
  }, [dispatch]);
  const updatecaste = (e: any) => {
    e.preventDefault();
    httpClients
      .PUT("affectedvictims/" + id, { name }, true)
      .then((res) => {
        toast({ title: "affected victims has been updated!", status: "success" });
        navigate(-1);
        httpClients.GET("affectedvictims", true).then((res) => {
          dispatch(setaffectedvictims(res.data.data));
        });
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating affected victims!",
          status: "error",
        });
      });
  };
  return (
    <AffectedVictimsLayout
      form_handler={updatecaste}
      btn_name="सम्पादन"
      title="सम्पादन"
    />
  );
};
export default UpdateAffectedVictims;
