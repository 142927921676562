import GenderLayout from ".";
import React from "react";
import { getspecificgender, setgender } from "../../../redux/gender";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
const CreateGender = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.gender.name);
  React.useEffect(() => {
    dispatch(getspecificgender(""));
  }, [dispatch]);
  const creategender = (e: any) => {
    e.preventDefault();
    httpClients
      .POST(
        "genders",
        {
          name,
        },
        true
      )
      .then((res) => {
        toast({ title: "Gender has been created!", status: "success" });
        httpClients.GET("genders", true).then((res) => {
          dispatch(setgender(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating gender!",
          status: "error",
        });
      });
  };
  return (
    <GenderLayout
      form_handler={creategender}
      btn_name="थप्नुहोस्"
      title="थप्नुहोस्"
    />
  );
};
export default CreateGender;
