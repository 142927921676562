import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { getspecificdisability, setdisability } from "../../../redux/disability";
import DisabiliytLayout from ".";
import { useNavigate } from "react-router";
const CreateDisability = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.disability.name);
  React.useEffect(() => {
    dispatch(getspecificdisability(""));
  }, [dispatch]);
  const creategender = (e: any) => {
    e.preventDefault();
    httpClients
      .POST("disabilities", {name}, true)
      .then((res) => {
        toast({
          title: "Disability type has been created!",
          status: "success",
        });
        httpClients.GET("disabilities", true).then((res) => {
          dispatch(setdisability(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating disability type!",
          status: "error",
        });
      });
  };
  return (
    <DisabiliytLayout
      form_handler={creategender}
      btn_name="थप्नुहोस्"
      title="थप्नुहोस्"
    />
  );
};
export default CreateDisability;
