import { useNavigate, useParams } from "react-router";
import RoleLayout from ".";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import {
  getSpecificrole,
  setrole,
  setrolespermission,
} from "../../../redux/role";
import { useEffect } from "react";

const RoleUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const role = useAppSelector((state: any) => state.role.specific_role);
  useEffect(() => {
    httpClients
      .GET("roles/" + id, true)
      .then((res: any) => {
        console.log(res.data.data);
        let data = res.data.data;
        httpClients.GET("roles", true).then((res: any) => {
          dispatch(setrole(res.data.data));
        });
        dispatch(
          getSpecificrole({
            role: data.role,
            permissions: data.permissions.map((p: any) => p.id),
          })
        );
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching roles data!",
          status: "error",
        });
        console.log(err);
      });
  }, [dispatch, id]);
  const form_handler = (e: any) => {
    e.preventDefault();
    httpClients
      .PUT("roles/" + id, role, true)
      .then((res) => {
        navigate("/user_configuration/role");
        httpClients.GET("/permissions/parent/children", true).then((res) => {
          dispatch(setrolespermission(res.data.data));
          console.log(res);
        });
        toast({ title: "Roles has been updated!", status: "success" });
      })
      .catch((err) => {
        toast({
          title: "Error occured while updating roles!",
          status: "error",
        });
      });
  };
  return (
    <RoleLayout
      title="सम्पादन"
      btn_name="सम्पादन"
      form_handler={form_handler}
      data={role}
    />
  );
};
export default RoleUpdate;
