import { createSlice } from "@reduxjs/toolkit";
interface SearchState {
  provinces: any;
  district: any;
  llevel: any;
  fiscal_year_id: any;
}

const initialState: SearchState = {
  provinces: 6,
  district: undefined,
  llevel: undefined,
  fiscal_year_id: 1,
};

export const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {
    set_search_province: (state, action) => {
      state.provinces = action.payload;
    },
    set_search_district: (state, action) => {
      state.district = action.payload;
    },
    set_search_llevel: (state, action) => {
      state.llevel = action.payload;
    },
    set_search_fiscal_year_id: (state, action) => {
      state.fiscal_year_id = action.payload;
    },
    reset_search_data: (state) => {
      state.provinces = 6;
      state.district = undefined;
      state.llevel = undefined;
      state.fiscal_year_id = 1;
    },
  },
});

export const {
  set_search_district,
  set_search_province,
  set_search_llevel,
  set_search_fiscal_year_id,
  reset_search_data
} = searchSlice.actions;

export default searchSlice.reducer;
