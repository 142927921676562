import { Button, Card, Center, Flex, Text } from "@chakra-ui/react";
import { HiPencil } from "react-icons/hi";
import { IoReturnDownBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router";

const ViewWrapper = (props: {
  title?: string;
  children?: JSX.Element | JSX.Element[];
  width?: string;
}) => {
  const navigate = useNavigate();
  return (
    <Center height="100%">
      <Card bgColor={"#fff"} p={4} width={props.width}>
        <Flex justifyContent={"space-between"}>
          <Button
            variant="outline"
            colorScheme="red"
            leftIcon={<IoReturnDownBackSharp />}
            onClick={() => navigate(-1)}
          >
            फर्किनु
          </Button>
          <Text fontSize={"28px"} fontWeight={500} pb={4} textAlign={"center"}>
            {props.title}
          </Text>
          <Button colorScheme="blue" rightIcon={<HiPencil />}>
            सम्पादन
          </Button>
        </Flex>
        {props.children}
      </Card>
    </Center>
  );
};
export default ViewWrapper;
