import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import { getspecificcaste, setcaste } from "../../../redux/caste_tribes";
import CasteLayout from ".";
const UpdateCaste = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const name = useAppSelector((state) => state.caste.name);
  React.useEffect(() => {
    httpClients
      .GET("castetribes/" + id, true)
      .then((res) => {
        console.log(res.data.data);
        dispatch(getspecificcaste(res.data.data.name));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching caste data!",
          status: "error",
        });
      });
  }, [dispatch]);
  const updatecaste = (e: any) => {
    e.preventDefault();
    httpClients
      .PUT("castetribes/" + id, {name}, true)
      .then((res) => {
        toast({ title: "castetribes has been updated!", status: "success" });
        navigate(-1);
        httpClients.GET("castetribes", true).then((res) => {
          dispatch(setcaste(res.data.data));
        });
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating castetribes!",
          status: "error",
        });
      });
  };
  return (
    <CasteLayout
      form_handler={updatecaste}
      btn_name="सम्पादन"
      title="सम्पादन"
    />
  );
};
export default UpdateCaste;
