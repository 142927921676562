import UserLayout from ".";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useAppDispatch } from "../../../hooks/redux_hook";
import { adduser, resetUserForm } from "../../../redux/user";
import { useNavigate } from "react-router";
import { useEffect } from "react";
const UserCreate = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetUserForm());
  });
  const create_user = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    console.log(data);
    httpClients
      .POST("/users", data, true)
      .then((res) => {
        toast({ title: "User has been created!", status: "success" });
        dispatch(adduser(res.data.user));
        navigate(-1);
      })
      .catch((err) => {
        toast({ title: "Error occured while creating user", status: "error" });
      });
  };
  return (
    <UserLayout
      title="सिर्जना"
      btn_name="सिर्जना"
      form_handler={create_user}
      password={true}
    />
  );
};
export default UserCreate;
