import { Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
const SubLink = (props: {
  title?: string;
  click_handler?: any;
  url?: string;
  active?: boolean;
  icon?: JSX.Element;
}) => {
  return (
    <NavLink
      to={props.url ? props.url : ""}
      onClick={props.click_handler}
      // className={style.nav}
      className={({ isActive }) =>
          isActive
            ? "nav nav_active"
            : "nav"
        }
    >
      <Text
        mb={0}
        borderBottom={"1px solid #ccc"}
        color={props.active ? "blue.600" : "#5c6c74"}
        p={1}
      >
        {props.icon}
        {props.title}
      </Text>
    </NavLink>
  );
};
export default SubLink;
