import { Button, Card, Flex, Tooltip } from "@chakra-ui/react";
import SelectInput from "../../../component/input/input_select";
import { CgSearchLoading } from "react-icons/cg";
import { useEffect } from "react";
import httpClients from "../../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import {
  set_district,
  set_llevel,
  set_province,
} from "../../../redux/extra_field";
import style from "./index.module.scss";
import { BiReset } from "react-icons/bi";
import {
  set_search_district,
  set_search_fiscal_year_id,
  set_search_llevel,
  set_search_province,
} from "../../../redux/search_bar";
import { setfiscalyear } from "../../../redux/fiscalyear";
const SearchBar = (props: {
  ward?: boolean;
  search_handler?: any;
  reset_btn?: any;
}) => {
  const dispatch = useAppDispatch();
  const province = useAppSelector((state: any) => state.extraField.provinces);
  const district = useAppSelector((state: any) => state.extraField.district);
  const llevel = useAppSelector((state: any) => state.extraField.llevel);
  const fiscalyears = useAppSelector((state: any) => state.fiscalyear.data);
  const district_value = useAppSelector(
    (state: any) => state.search_bar.district
  );
  const llevel_value = useAppSelector((state: any) => state.search_bar.llevel);
  const fiscal_year_id = useAppSelector(
    (state: any) => state.search_bar.fiscal_year_id
  );

  useEffect(() => {
    httpClients.GET("provinces", true).then((res) => {
      console.log(res.data.data);
      dispatch(set_province(res.data.data));
    });
    httpClients.GET("districts", true).then((res) => {
      dispatch(set_district(res.data.data));
    });
    httpClients.GET("llevels", true).then((res) => {
      dispatch(set_llevel(res.data.data));
    });
    httpClients.GET("fiscalyears", true).then((res) => {
      dispatch(setfiscalyear(res.data.data));
    });
  }, [dispatch]);
  const searchData = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(
      set_search_fiscal_year_id(parseInt(data.get("fiscalyears") as any))
    );
    dispatch(set_search_province(parseInt(data.get("province") as any)));
    dispatch(set_search_district(parseInt(data.get("district") as any)));
    dispatch(set_search_llevel(parseInt(data.get("llevel") as any)));
    props.search_handler();
  };
  return (
    <>
      <form onSubmit={searchData}>
        <Card p={2}>
          <Flex direction={"row"} gap={2} alignItems={"center"}>
            <SelectInput
              placeholder="आ.व. छान्नुहोस"
              options={
                fiscalyears &&
                fiscalyears?.map((p: any) => {
                  return { value: p?.id, label: p?.name };
                })
              }
              on_change={(e: any) =>
                dispatch(set_search_fiscal_year_id(e.value))
              }
              value={
                fiscalyears &&
                fiscalyears
                  ?.map((p: any) => {
                    return { value: p?.id, label: p?.name };
                  })
                  .filter((p: any) => p.value === fiscal_year_id)
              }
              name="fiscalyears"
            />
            <SelectInput
              placeholder="प्रदेश छान्नुहोस"
              value={
                province &&
                province
                  .filter((p: any) => p.id === 6)
                  .map((p: any) => {
                    return { value: p?.id, label: p?.province_np };
                  })
              }
              on_change={(e: any) => dispatch(set_search_province(e.value))}
              name="province"
            />
            <SelectInput
              placeholder="जिल्ला छान्नुहोस"
              options={
                district &&
                district
                  .filter((p: any) => p?.province?.id === 6)
                  ?.map((p: any) => {
                    return { value: p?.id, label: p?.district_np };
                  })
              }
              on_change={(e: any) => dispatch(set_search_district(e.value))}
              value={
                district &&
                district
                  .filter((p: any) => p?.province?.id === 6)
                  ?.map((p: any) => {
                    return { value: p?.id, label: p?.district_np };
                  })
                  .filter((p: any) => p.value === district_value)
              }
              name="district"
            />
            <SelectInput
              placeholder="स्थानीय तह छान्नुहोस"
              options={
                llevel &&
                llevel
                  .filter((p: any) => p?.district?.id === district_value)
                  ?.map((p: any) => {
                    return { value: p?.id, label: p?.lgname_np };
                  })
              }
              on_change={(e: any) => dispatch(set_search_llevel(e.value))}
              value={
                llevel &&
                llevel
                  .filter((p: any) => p?.district?.id === district_value)
                  ?.map((p: any) => {
                    return { value: p?.id, label: p?.lgname_np };
                  })
                  .filter((p: any) => p.value === llevel_value)
              }
              name="llevel"
            />

            <Button
              leftIcon={<CgSearchLoading />}
              colorScheme="blue"
              minWidth="max-content"
              type="submit"
            >
              खोज्नुहोस
            </Button>
            <Tooltip
              label="डेटा रिसेट गर्नुहोस्"
              bg="blue.600"
              hasArrow
              color="white"
              aria-label="A tooltip"
            >
              <Button
                rightIcon={<BiReset />}
                className={style.reset_button}
                type="button"
                onClick={props.reset_btn}
              ></Button>
            </Tooltip>
          </Flex>
        </Card>
      </form>
    </>
  );
};
export default SearchBar;
