import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import ComponentWrapper from "../../../component/component_wrapper";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { setSpecificofficetype } from "../../../redux/office_type/index";
const OfficeTypeLayout = (props: {
  title?: string;
  btn_name?: string;
  form_handler?: any;
}) => {
  const dispatch = useAppDispatch();
  const name = useAppSelector((state: any) => state.office_type.office_type);
  const input_change = (e: any) => {
    dispatch(setSpecificofficetype(e.target.value));
  };
  return (
    <ComponentWrapper
      title={"कार्यालय प्रकार " + props.title}
      action_btn={props.btn_name}
      form_submit_handler={props.form_handler}
      width="50%"
      icon={<AiOutlineUserAdd />}
    >
      <FormControl isRequired>
        <FormLabel p={0} m={0}>
          कार्यालय प्रकार
        </FormLabel>
        <InputGroup>
          <Input
            fontWeight="500"
            type="text"
            name="office_type"
            placeholder="कार्यालय प्रकार प्रविष्ट गर्नुहोस्"
            value={name}
            onChange={input_change}
          />
        </InputGroup>
      </FormControl>
    </ComponentWrapper>
  );
};
export default OfficeTypeLayout;
