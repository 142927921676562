import { createSlice } from "@reduxjs/toolkit";
const data = {
  fiscal_year_id: 0,
  incident_report_date: null,
  affected_hint: "",
  age: 0,
  address: "",
  caste_tribe_id: "",
  province_id: 6,
  district_id: 0,
  llevel_id: 0,
  gender_id: 0,
  incident_background: "",
  disability_people_id: "",
  voilence_type_id: "",
  first_followup: "",
  physical_exam: "",
  fmedical_legal_test: "",
  hiv_vol_testing: "",
  pregnancy_test: "",
  wounds_treatment: "",
  emergency_contraceptive_service: "",
  sexual_disease_treatment: "",
  safe_abortion_service: "",
  mental_disease_treatment: "",
  psychosocial_con_service: "",
  other_treatment_service: "",
  affected_victim_id: "",
  affected_service_center_id: "",
  others: "",
  afflicted_name: "",
};
interface CaseState {
  all_data: any;
  view_case_data: any;
  data: {
    fiscal_year_id: number;
    incident_report_date: any;
    affected_hint: string;
    age: number;
    address: string;
    caste_tribe_id: string;
    province_id: number;
    district_id: number;
    llevel_id: number;
    gender_id: number;
    incident_background: string;
    disability_people_id: string;
    voilence_type_id: string;
    first_followup: string;
    physical_exam: string;
    fmedical_legal_test: string;
    hiv_vol_testing: string;
    pregnancy_test: string;
    wounds_treatment: string;
    emergency_contraceptive_service: string;
    sexual_disease_treatment: string;
    safe_abortion_service: string;
    mental_disease_treatment: string;
    psychosocial_con_service: string;
    other_treatment_service: string;
    affected_victim_id: string;
    affected_service_center_id: string;
    others: string;
    afflicted_name: string;
  };
  status: string;
}

const initialState: CaseState = {
  view_case_data: undefined,
  all_data: undefined,
  data: { ...data },
  status: "create",
};

export const caseSlice = createSlice({
  name: "caseSlice",
  initialState,
  reducers: {
    set_data: (state, action) => {
      state.data = action.payload;
    },
    set_specific_field: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.name]: action.payload.value,
      };
    },
    set_all_data: (state, action) => {
      state.all_data = action.payload;
    },
    reset_case_form: (state) => {
      state.data = data;
    },
    set_view_case_data: (state, action) => {
      state.view_case_data = action.payload;
    },
    set_status: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const {
  set_data,
  set_specific_field,
  set_all_data,
  reset_case_form,
  set_view_case_data,
  set_status,
} = caseSlice.actions;

export default caseSlice.reducer;
