import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./login/index";
import SidebarSlice from "./sidebar";
import userSlice from "./user";
import TableSlice from "./table";
import roleSlice from "./role";
import permissionSlice from "./permission";
import tabSlice from "./tabs";
import extraSlice from "./extra_field";
import caseSlice from "./case";
import reportSlice from "./reports";
import searchSlice from "./search_bar";
import genderSlice from "./gender";
import casteSlice from "./caste_tribes";
import voilenceSlice from "./voilence";
import disabilitySlice from "./disability";
import AffectedVictimSlice from "./affected_victim";
import AffectedServicesSlice from "./affected_services";
import fiscalyearSlice from "./fiscalyear";
import officeSlice from "./office";
import provinceSlice from "./geo/provinces/province";
import llevelSlice from "./geo/local_level";
import districtSlice from "./geo/districts";
import officeTypeSlice from "./office_type";
export const store = configureStore({
  reducer: {
    login: loginSlice,
    sidebar: SidebarSlice,
    user: userSlice,
    table: TableSlice,
    role: roleSlice,
    permission: permissionSlice,
    tabs: tabSlice,
    extraField: extraSlice,
    case: caseSlice,
    report: reportSlice,
    search_bar: searchSlice,
    gender: genderSlice,
    caste: casteSlice,
    voilence: voilenceSlice,
    disability: disabilitySlice,
    affectedvictims: AffectedVictimSlice,
    affectedservices: AffectedServicesSlice,
    fiscalyear: fiscalyearSlice,
    office: officeSlice,
    province: provinceSlice,
    district: districtSlice,
    llevel: llevelSlice,
    office_type: officeTypeSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
