import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import {
  set_designation,
  set_designation_fields,
} from "../../../redux/extra_field";
import DesignationLayout from ".";
const DesignationUpdate = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const designation = useAppSelector(
    (state) => state.extraField.designation_fields
  );
  React.useEffect(() => {
    httpClients
      .GET("designations/" + id, true)
      .then((res) => {
        console.log(res.data.data);
        dispatch(
          set_designation_fields(res.data.data.designation)
        );
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching designations data!",
          status: "error",
        });
      });
  }, [dispatch]);
  const updatedesignation = (e: any) => {
    e.preventDefault();
    httpClients
      .PUT("designations/" + id, { designation }, true)
      .then((res) => {
        toast({ title: "designations has been updated!", status: "success" });
        navigate(-1);
        httpClients.GET("designations", true).then((res) => {
          dispatch(set_designation(res.data.data));
        });
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating designation!",
          status: "error",
        });
      });
  };
  return (
    <DesignationLayout
      form_handler={updatedesignation}
      btn_name="सम्पादन"
      title="सम्पादन"
    />
  );
};
export default DesignationUpdate;
