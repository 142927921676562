import { Flex, Skeleton, Stack, useToast } from "@chakra-ui/react";
import { DataTable } from "../../component/data_table";
import SearchBar from "./search_bar";
import { useEffect } from "react";
import httpClients from "../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_all_data } from "../../redux/case";
import { set_modal } from "../../redux/extra_field";
import { reset_search_data } from "../../redux/search_bar";
import { reset_case } from "../../api/reset_case";
import { changeIndexToNepali } from "../../hooks/number_translation";
import { useState } from "react";
const CaseReportModule = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const all_data = useAppSelector((state) => state.case.all_data);
  const toast = useToast();
  const fiscal_year_id = useAppSelector(
    (state: any) => state.search_bar.fiscal_year_id
  );
  const province_id = useAppSelector((state) => state.search_bar.provinces);
  const district_id = useAppSelector((state) => state.search_bar.district);
  const llevel_id = useAppSelector((state) => state.search_bar.llevel);
  const api_call = async () => {
    setLoading(true);
    await httpClients
      .GET(
        `gvs?fiscal_year_id=${fiscal_year_id}${
          province_id ? "&" : "?"
        }province_id=${province_id}${
          province_id ? "&" : "?"
        }district_id=${district_id}${
          province_id ? "&" : "?"
        }llevel_id=${llevel_id}`,
        true
      )
      .then((res) => {
        setLoading(false);
        dispatch(set_all_data(res.data.data?.GenderViolences));
        console.log(res.data.data);
      })
      .catch((err) => {
        setLoading(false);

        toast({
          title: "Error occured while fetching GVS data",
          status: "error",
        });
      });
  };
  useEffect(() => {
    dispatch(reset_search_data());
    api_call();
  }, [dispatch]);
  const columns = [
    {
      header: "क्र.स.",
      accessorKey: "id",
    },
    {
      header: "प्रभावितको सङ्केत",
      accessorKey: "voilence_type",
    },
    {
      header: "पीडितको नाम",
      accessorKey: "afflicted_name",
    },
    {
      header: "लिङ्ग",
      accessorKey: "gender",
    },
    {
      header: "उमेर",
      accessorKey: "age",
    },
    {
      header: "ठेगाना",
      accessorKey: "address",
    },
    {
      header: "घटना दर्ता मिति",
      accessorKey: "incident_report_date",
    },
  ];
  const deleteHandler = (delete_id: number) => {
    httpClients
      .DELETE("gvs/" + delete_id, true)
      .then((res) => {
        dispatch(set_modal(false));

        toast({
          title: "डेटा सफलतापूर्वक मेटिएको छ!",
          status: "success",
        });
        httpClients.GET("gvs", true).then((res) => {
          dispatch(set_all_data(res.data.data?.GenderViolences));
        });
      })
      .catch((err) => {
        dispatch(set_modal(false));
        toast({
          title: "Error occured while deleting gvs data",
          status: "error",
        });
      });
  };
  const search_handler = (data: any) => {
    api_call();
  };
  const reset_handler = () => {
    dispatch(reset_search_data());
    reset_case();
  };
  return (
    <Flex direction={"column"} gap={4}>
      <SearchBar search_handler={search_handler} reset_btn={reset_handler} />
      {!loading && (
        <DataTable
          nep
          download
          title="केस तालिका"
          actions
          btn
          docs
          data={
            all_data?.length > 0 &&
            all_data?.map((p: any) => {
              return {
                id: p.id,
                incident_report_date: p?.incident_report_date,
                age: changeIndexToNepali(p?.age),
                address: p?.address,
                gender: p?.gender_name,
                afflicted_name: p?.afflicted_name,
                voilence_type: p?.voilence_type_name,
                case_file_path: p?.case_file_path,
              };
            })
          }
          columns={columns}
          delete_handler={deleteHandler}
        />
      )}
      {loading && (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Flex>
  );
};
export default CaseReportModule;
