import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import {
  set_designation,
  set_designation_fields,
} from "../../../redux/extra_field";
import DesignationLayout from ".";
const CreateDesignation = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const designation = useAppSelector(
    (state) => state.extraField.designation_fields
  );
  React.useEffect(() => {
    dispatch(set_designation_fields(""));
  }, [dispatch]);
  const createcaste = (e: any) => {
    e.preventDefault();
    httpClients
      .POST("designations", { designation }, true)
      .then((res) => {
        toast({
          title: "Designation type has been created!",
          status: "success",
        });
        httpClients.GET("designations", true).then((res) => {
          dispatch(set_designation(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating designations!",
          status: "error",
        });
      });
  };
  return (
    <DesignationLayout
      form_handler={createcaste}
      btn_name="थप्नुहोस्"
      title="थप्नुहोस्"
    />
  );
};
export default CreateDesignation;
