import { createSlice } from "@reduxjs/toolkit";
interface FiscalYearState {
  data: Array<Object>;
  name: string;
}

const initialState: FiscalYearState = {
  data: [],
  name: "",
};

export const fiscalyearSlice = createSlice({
  name: "fiscalyearSlice",
  initialState,
  reducers: {
    setfiscalyear: (state, action) => {
      state.data = action.payload;
    },
    getspecificfiscalyear: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setfiscalyear, getspecificfiscalyear } = fiscalyearSlice.actions;

export default fiscalyearSlice.reducer;
