import ComponentWrapper from "../../../component/component_wrapper";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import SelectInput from "../../../component/input/input_select";
import httpClients from "../../../utils/httpClients";
import { useEffect } from "react";
import {
    get_district_fields,
  set_district,
  set_district_fields,
  set_province,
} from "../../../redux/extra_field";
import { Flex, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
const DistrictUpdate = (props: {
  title?: string;
  btn_name?: string;
  form_handler?: any;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const provinces = useAppSelector((state) => state.extraField.provinces);
  const districts = useAppSelector((state) => state.extraField.district);

  const specific_district = useAppSelector(
    (state) => state.extraField.district_fields
  );
  useEffect(() => {
    httpClients
      .GET("/provinces", true)
      .then((res: any) => {
        dispatch(set_province(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching provinces",
          status: "error",
        });
      });
    httpClients
      .GET("/districts", true)
      .then((res: any) => {
        dispatch(set_district(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching districts",
          status: "error",
        });
      });

    httpClients
      .GET("/districts/" + id, true)
      .then((res) => {
        let data = res.data.data;
        console.log(data.did);
        dispatch(
          get_district_fields({
            province_id: data?.province?.id,
            district_id: data?.did,
          })
        );
      })
      .catch((err) => {
        toast({
          title: "Error while processing the district data !!",
          status: "error",
        });
      });
  }, [dispatch]);
  const select_change = (name: string, value: any) => {
    dispatch(set_district_fields({ name, value }));
  };
  const form_handler = async (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    await httpClients
      .PUT("/districts/" + id, form, true)
      .then((res) => {
        toast({ title: "District has been updated!", status: "success" });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error while updating districts!",
          status: "error",
        });
      });
  };
  return (
    <ComponentWrapper
      title={"जिल्ला सम्पादन"}
      action_btn={"सम्पादन"}
      form_submit_handler={form_handler}
      width="50%"
    >
      <Flex gap={8}>
        <SelectInput
          label="प्रदेश"
          required
          placeholder="प्रदेश छान्नुहोस्"
          name="province_id"
          value={
            provinces &&
            provinces
              .filter((p: any) => p.id === 6)
              .map((p: any) => {
                return { value: p?.id, label: p?.province_np };
              })
          }
        />
        <SelectInput
          label="जिल्ला"
          required
          placeholder="जिल्ला छान्नुहोस्"
          name="district_id"
          options={
            districts &&
            districts?.map((p: any) => {
              return { value: p?.did, label: p?.district_np };
            })
          }
          value={
            districts &&
            districts
              .map((p: any) => {
                return { label: p?.district_np, value: p?.did };
              })
              ?.filter(
                (p: any) =>
                  p.value.toString() ===
                  specific_district?.district_id?.toString()
              )
          }
          on_change={(e: any) => select_change("district_id", e.value)}
        />
      </Flex>
    </ComponentWrapper>
  );
};
export default DistrictUpdate;
