import { createSlice } from "@reduxjs/toolkit";
interface UserState {
  data: Array<Object>;
  loading: false;
  edit_id: number;
  specific_user: {
    name: string;
    address: string;
    email: string;
    phone_number: string;
    password: string;
    office_id: number;
    designation_id: number;
    roles: Array<number>;
    active_status: number;
  };
}

const initialState: UserState = {
  data: [],
  edit_id: 0,
  loading: false,
  specific_user: {
    name: "",
    address: "",
    email: "",
    phone_number: "",
    password: "",
    office_id: 0,
    designation_id: 0,
    roles: [],
    active_status: 0,
  },
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    set_loading_user: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.data = action.payload;
    },
    adduser: (state, action) => {
      console.log(action.payload);
      state.data = [...state.data, action.payload];
    },
    updateuser: (state, action) => {
      let data = [...state.data];
      data[state.edit_id] = action.payload;
      console.log(data);
      state.data = [...data];
    },
    setEditId: (state, action) => {
      console.log(action.payload);
      state.edit_id = action.payload;
    },
    resetUserForm: (state) => {
      state.specific_user = {
        name: "",
        address: "",
        email: "",
        phone_number: "",
        password: "",
        office_id: 0,
        designation_id: 0,
        roles: [],
        active_status: 0,
      };
    },
    getSpecificuser: (state, action) => {
      state.specific_user = {
        ...action.payload,
        office_id: action.payload.office.id,
        designation_id: action.payload.designation.id,
        roles: action.payload.roles.map((p: any) => p.id),
      };
    },
    setSpecificuser: (state, action) => {
      console.log(action.payload);
      state.specific_user = {
        ...state.specific_user,
        [action.payload.name]: action.payload.value,
      };
    },
    deleteuser: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setUser,
  adduser,
  deleteuser,
  getSpecificuser,
  setSpecificuser,
  resetUserForm,
  updateuser,
  setEditId,
  set_loading_user
} = userSlice.actions;

export default userSlice.reducer;
