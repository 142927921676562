import { createSlice } from "@reduxjs/toolkit";
interface SidebarState {
  sidebar?: boolean;
}

const initialState: SidebarState = {
  sidebar: true,
};

export const SidebarSlice = createSlice({
  name: "SidebarSlice",
  initialState,
  reducers: {
    set_sidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },
});

export const { set_sidebar } = SidebarSlice.actions;
export default SidebarSlice.reducer;
