import {
  InputGroup,
  InputLeftElement,
  Input,
  Checkbox,
  Button,
  Stack,
  InputRightElement,
  Link,
  Center,
  Text,
  Image,
  FormControl,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { BiLock } from "react-icons/bi";
import React, { useState } from "react";
import GovImg from "../../assets/mofoga.png";
import "./index.scss";
import { useAppDispatch } from "../../hooks/redux_hook";
import { setAccess, setLogin } from "../../redux/login";
import { useNavigate } from "react-router";
import httpClients from "../../utils/httpClients";
function Login() {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [show, setShow] = React.useState(false);
  const [checked, setChecked] = useState(true);
  const handleClick = () => setShow(!show);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    httpClients
      .POST(
        "login",
        { email: data.get("email"), password: data.get("password") },
        false
      )
      .then(async (res) => {
        if (res.status === 200) {
          dispatch(setLogin());
          console.log(res);
          if (checked) {
            localStorage.setItem("gbv_key", res.data.token);
            localStorage.setItem("user_id", res.data.user_id);
          } else {
            sessionStorage.setItem("gbv_key", res.data.token);
            sessionStorage.setItem("user_id", res.data.user_id);
          }
          await httpClients.GET("users/permissions", true).then((res) => {
            dispatch(setAccess(res.data?.access_permissions));
            if (checked) {
              localStorage.setItem("access", res.data?.access_permissions);
            } else {
              sessionStorage.setItem("access", res.data?.access_permissions);
            }
          });
          navigate("/");

          toast({
            title: "व्यवस्थापक प्यानलमा स्वागत छ!",
            status: "success",
          });
        }
      })
      .catch((e) => {
        toast({ title: "लगइन गर्दा त्रुटि भयो!", status: "error" });
      });
  };
  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };
  return (
    <>
      <Center
        height="100%"
        width="100vw"
        bgGradient="linear(to-bl,blue.200 10%,blue.500 100%)"
      >
        <form
          autoComplete="off"
          style={{ width: "30%" }}
          onSubmit={handleSubmit}
        >
          <fieldset
            style={{
              border: "1px solid #fff",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            <legend style={{ textAlign: "center" }}>
              <div className="half-circle">
                <Image
                  src={GovImg}
                  objectFit="cover"
                  alt="Gov Logo"
                  height="80px"
                />
              </div>
            </legend>
            <Text textAlign={"center"} fontWeight={"500"}>
              <Text display={"inline"} color={"red.500"} fontSize={"16px"}>
                लैङ्गिक हिंसा मुद्दा व्यवस्थापन प्रणाली <br />
              </Text>
              <Text display={"inline"} color={"blue.400"} fontSize={"20px"}>
                कर्णाली प्रदेश सरकार सामाजिक विकास मन्त्रालय
              </Text>
              <br />
              <span style={{ fontSize: "16px" }}>
                विरेन्द्रनगर,सुर्खेत,नेपाल
              </span>
            </Text>
            <Stack p={6} direction="column" spacing={6}>
              <Stack direction="column" spacing={6}>
                <Stack direction="column" spacing={3}>
                  <FormControl isRequired>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<AiOutlineUser />}
                      />
                      <Input
                        fontWeight="500"
                        type="email"
                        placeholder="user@email.com"
                        className="auth_input"
                        name="email"
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl isRequired>
                    <InputGroup size="md">
                      <InputLeftElement
                        pointerEvents="none"
                        children={<RiLockPasswordLine />}
                      />
                      <Input
                        pr="4.5rem"
                        fontWeight="500"
                        type={show ? "text" : "password"}
                        placeholder="*******************"
                        className="auth_input"
                        name="password"
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Checkbox
                    colorScheme="blue"
                    defaultChecked
                    onChange={checkHandler}
                  >
                    <Text fontSize="16px">लगइन अवस्था मै राख्नुहोस्</Text>
                  </Checkbox>
                  {/* <Link
                    fontSize="16px"
                    textDecoration={"underline"}
                    color="blue.400"
                    href="#"
                  >
                    पासवर्ड बिर्सनुभयो ?
                  </Link> */}
                </Stack>
              </Stack>
              <Button
                width="100%"
                leftIcon={<BiLock />}
                colorScheme="blue"
                variant="solid"
                type="submit"
                fontSize="16px"
              >
                लगइन
              </Button>
            </Stack>
          </fieldset>
        </form>
      </Center>
    </>
  );
}

export default Login;
