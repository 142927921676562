import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { getspecificfiscalyear, setfiscalyear } from "../../../redux/fiscalyear";
import FiscalYearLayout from ".";
const CreateFiscalYear = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.fiscalyear.name);
  React.useEffect(() => {
    dispatch(getspecificfiscalyear(""));
  }, [dispatch]);
  const creategender = (e: any) => {
    e.preventDefault();
    httpClients
      .POST(
        "fiscalyears",
        {
          name,
        },
        true
      )
      .then((res) => {
        toast({ title: "Fiscal year has been created!", status: "success" });
        httpClients.GET("fiscalyears", true).then((res) => {
          dispatch(setfiscalyear(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating fiscal years!",
          status: "error",
        });
      });
  };
  return (
    <FiscalYearLayout
      form_handler={creategender}
      btn_name="थप्नुहोस्"
      title="थप्नुहोस्"
    />
  );
};
export default CreateFiscalYear;
