import { createSlice } from "@reduxjs/toolkit";
interface LocallevelState {
  data: Array<Object>;
  specific_llevel: {
    lgname_en: string;
    lgname_np: string;
    province_id: number;
    district_id: number;
  };
  loading: boolean;
}

const initialState: LocallevelState = {
  data: [],
  specific_llevel: {
    lgname_en: "",
    lgname_np: "",
    province_id: 0,
    district_id: 0,
  },
  loading: false,
};

export const llevelSlice = createSlice({
  name: "llevelSlice",
  initialState,
  reducers: {
    set_loading_llevel: (state, action) => {
      state.loading = action.payload;
    },
    setllevel: (state, action) => {
      state.data = action.payload;
    },
    addllevel: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    getSpecificllevel: (state, action) => {
      state.specific_llevel = {
        ...action.payload,
      };
    },
    setSpecificllevel: (state, action) => {
      state.specific_llevel = {
        ...state.specific_llevel,
        [action.payload.name]: action.payload.value,
      };
    },
    deletellevel: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setllevel,
  addllevel,
  deletellevel,
  getSpecificllevel,
  setSpecificllevel,
  set_loading_llevel,
} = llevelSlice.actions;

export default llevelSlice.reducer;
