import { Stack, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../utils/httpClients";
import { DataTable } from "../../component/data_table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { deletepermission, setpermission } from "../../redux/permission";
import { set_modal } from "../../redux/extra_field";

const Permission = () => {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector((state) => state.permission.data);
  const toast = useToast();
  useEffect(() => {
    httpClients
      .GET("permissions", true)
      .then((res) => {
        dispatch(setpermission(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching permissions",
          status: "error",
        });
      });
  }, [dispatch, toast]);
  const columns = [
    {
      header: "S.N.",
      accessorKey: "id",
    },
    {
      header: "permission name",
      accessorKey: "permission_name",
    },
    {
      header: "guard name",
      accessorKey: "guard_name",
    },
  ];
  const delete_permissions = (delete_id: number) => {
    if (delete_id !== undefined) {
      httpClients
        .DELETE("permissions/" + delete_id, true)
        .then((res) => {
          dispatch(deletepermission(delete_id));
          toast({ title: "Permission has been deleted!", status: "success" });
          dispatch(set_modal(false));
        })
        .catch((err) => {
          toast({
            title: "Error occured while deleting permision!",
            status: "error",
          });
        });
    }
  };
  return (
    <>
      <DataTable
        btn
        title="अनुमति"
        actions
        data={
          permissions &&
          permissions.map((p: any) => {
            return {
              id: p?.id,
              permission_name: p?.permission_name,
              guard_name: p?.guard_name,
            };
          })
        }
        columns={columns}
        delete_handler={delete_permissions}
      />
    </>
  );
};
export default Permission;
