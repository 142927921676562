import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { setSpecificofficetype, set_loading_officetype } from "../../../redux/office_type";
import OfficeTypeLayout from ".";
const CreateOfficeType = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(setSpecificofficetype(""));
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    dispatch(set_loading_officetype(true));
    httpClients.POST("/officetypes", { office_type: form.get("office_type") }, true)
      .then((res) => {
        toast({title:"Office type has been added",status:"success"});
        dispatch(set_loading_officetype(false));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(set_loading_officetype(false));
        toast({title:"Error occured while adding office type!",status:"error"});
      });
  };
  return (
    <OfficeTypeLayout
      form_handler={form_handler}
      btn_name="थप्नुहोस्"
      title="थप्नुहोस्"
    />
  );
};
export default CreateOfficeType;
