import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import ComponentWrapper from "../../../component/component_wrapper";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { set_designation_fields } from "../../../redux/extra_field";

const DesignationLayout = (props: {
  title?: string;
  btn_name?: string;
  form_handler?: any;
}) => {
  const dispatch = useAppDispatch();
  const designation = useAppSelector((state) => state.extraField.designation_fields);
  const input_change = (e: any) => {
    dispatch(set_designation_fields(e.target.value));
  };
  return (
    <ComponentWrapper
      title={"पदनाम " + props.title}
      action_btn={props.btn_name}
      form_submit_handler={props.form_handler}
      width="50%"
      icon={<AiOutlineUserAdd />}
    >
      <FormControl isRequired>
        <FormLabel p={0} m={0}>
          पदनाम
        </FormLabel>
        <InputGroup>
          <Input
            fontWeight="500"
            type="text"
            name="name"
            placeholder="पदनाम प्रविष्ट गर्नुहोस्"
            value={designation}
            onChange={input_change}
          />
        </InputGroup>
      </FormControl>
    </ComponentWrapper>
  );
};
export default DesignationLayout;
