import { useNavigate, useParams } from "react-router";
import PermissionLayout from ".";
import { useAppDispatch } from "../../../hooks/redux_hook";
import { useEffect } from "react";
import {
  addpermission,
  getSpecificpermission,
  resetFormPermission,
  setpermission,
} from "../../../redux/permission";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";

const UpdatePermission = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const toast = useToast();
  useEffect(() => {
    httpClients
      .GET("permissions/" + id, true)
      .then((res) => {
        dispatch(getSpecificpermission(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching permission data!",
          status: "error",
        });
      });
  }, [dispatch, id]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form_data = new FormData(e.target);
    httpClients
      .PUT("permissions/" + id, form_data, true)
      .then(async (res) => {
        await httpClients.GET("permissions", true).then((res) => {
          console.log(res);
          dispatch(setpermission(res.data.data));
        });
        toast({ title: "Permission has been updated!", status: "success" });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occued while updating permission!",
          status: "error",
        });
      });
  };
  return (
    <PermissionLayout
      title="सम्पादन
    "
      btn_name="सम्पादन"
      form_handler={form_handler}
    />
  );
};
export default UpdatePermission;
