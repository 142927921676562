import { createSlice } from "@reduxjs/toolkit";
interface TableState {
  id?: number | null;
}

const initialState: TableState = {
  id: null,
};

export const TableSlice = createSlice({
  name: "TableSlice",
  initialState,
  reducers: {
    set_table_id: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { set_table_id } = TableSlice.actions;
export default TableSlice.reducer;
