import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_description_modal } from "../../redux/extra_field";
import karnalimap from "../../assets/karnali.png";
function ViewDescription(props: { delete_handler?: any }) {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((state) => state.extraField.description_modal);
  return (
    <Center>
      <Modal
        closeOnOverlayClick={true}
        isOpen={modal as any}
        onClose={() => dispatch(set_description_modal())}
        isCentered={true}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>लैङ्गिक हिंसा मुद्दा</ModalHeader>
          <ModalCloseButton />
          <Divider borderColor={"#ccc"} />
          <ModalBody>
            <Flex
              wrap={"wrap"}
              direction="row"
              justifyContent={"center"}
            >
              <Card width={"49%"} rounded="lg" p={2}>
                <CardHeader>
                  <Center>
                    <Text fontSize={20} fontWeight={500}>
                      लैङ्गिक हिंसा मुद्दा
                    </Text>
                  </Center>
                </CardHeader>
                <hr />
                <CardBody fontSize={14}>
                  लैङ्गिक हिंसा एउटा सामाजिक समस्याकैरूपमा रहेको छ । यसबाट
                  खासगरी महिला र बालबालिकाहरू बढी प्रभावित हुने गरेका छन् । यसले
                  उनीहरूमा शारीरिक, मानसिक, मनोवैज्ञानिक एवम् यौनजन्य असर
                  पार्नुका साथै उनीहरूको समग्र विकास, सुरक्षा, सामाजिक जीवन तथा
                  न्यायप्राप्तिमा समेत गम्भीर प्रभाव पार्दछ । तसर्थ, कसैलाई पनि
                  लैङ्गिक हिंसाको शिकार हुन नदिनुर भइहालेमा तत्कालै सोकोउपचार र
                  न्यायप्राप्तिको व्यवस्था गर्नु वाञ्छनीय भएकाले नेपालको
                  संविधानको मौलिक हक र कर्तव्य अन्तर्गत धारा ३८ मा उल्लेखित
                  महिलाको हक अन्तर्गत उपधारा ३ को भावना बमोजिम, नेपाल सरकारबाट
                  स्वीकृत लैङ्गिक हिंसाकोअन्त्य तथा लैङ्गिक सशक्तीकरणसम्बन्धी
                  राष्ट्रिय रणनीति तथा कार्ययोजना, २०६९÷२०७० को दफा ८ (क) (४)
                  अनुसार र अस्पतालमा आधारित लैङ्गिक हिंसा सम्बन्धी एकद्धार संकट
                  व्यवस्थापन केन्द्र स्थापना तथा संचालन निर्देशिका, २०६७
                  (परिमार्जन, २०७७ सहित) वमोजिम लैङ्गिक हिंसासम्बन्धी मुद्दामा
                  उपचार र सहयोग गर्न स्वास्थ्यकर्मीहरूका लागि यो क्लिनिकल
                  प्रोटोकल तयार गरी जारी गरिएको छ ।
                </CardBody>
              </Card>
              <Card width={"49%"} rounded="lg">
                <Center h="100%"><Image src={karnalimap} alt="..." /></Center>
              </Card>
            </Flex>
          </ModalBody>
          <ModalFooter pt={0}>
            <Button onClick={() => dispatch(set_description_modal())}>
              बन्द गर्नुहोस्
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
}
export default ViewDescription;
