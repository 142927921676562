import { Button, Flex } from "@chakra-ui/react";
import {
  HiOutlineArrowLongLeft,
  HiOutlineArrowLongRight,
} from "react-icons/hi2";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_tab_id } from "../../redux/tabs";

const TabButton = (props: { prev_btn?: any; next_btn?: any }) => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.tabs?.tab_id);
  const prev_btn = () => {
    if (activeTab !== 0) {
      dispatch(set_tab_id(activeTab - 1));
    }
  };
  return (
    <Flex gap={2} justifyContent={"flex-end"} pb={4} pr={4}>
      <Button
        variant={"outline"}
        colorScheme="red"
        leftIcon={<HiOutlineArrowLongLeft />}
        isDisabled={activeTab === 0}
        type="button"
        onClick={prev_btn}
      >
        अघिल्लो
      </Button>
      <Button
        colorScheme="blue"
        rightIcon={<HiOutlineArrowLongRight />}
        type="submit"
      >
        {activeTab === 3 ? "पठाउनुहोस्" : "पछिल्लो"}
      </Button>
    </Flex>
  );
};

export default TabButton;
