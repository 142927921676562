import React from "react";
import { getspecificgender } from "../../../redux/gender";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import {
  getspecificdisability,
  setdisability,
} from "../../../redux/disability";
import DisabiliytLayout from ".";
const UpdateDisability = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const name = useAppSelector((state) => state.disability.name);
  React.useEffect(() => {
    httpClients
      .GET("disabilities/" + id, true)
      .then((res) => {
        console.log(res.data.data);
        dispatch(getspecificdisability(res.data.data.name));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching gender data!",
          status: "error",
        });
      });
  }, [dispatch]);
  const creategender = (e: any) => {
    e.preventDefault();
    httpClients
      .PUT("disabilities/" + id, {name}, true)
      .then((res) => {
        toast({ title: "disabilities has been updated!", status: "success" });
        navigate(-1);
        httpClients.GET("disabilities", true).then((res) => {
          dispatch(setdisability(res.data.data));
        });
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating disabilities!",
          status: "error",
        });
      });
  };
  return (
    <DisabiliytLayout
      form_handler={creategender}
      btn_name="सम्पादन"
      title="सम्पादन"
    />
  );
};
export default UpdateDisability;
