import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../utils/httpClients";
import { DataTable } from "../../component/data_table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_modal } from "../../redux/extra_field";
import { deleteofficetype, setofficetype } from "../../redux/office_type";

const Office = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const office_type = useAppSelector((state) => state.office_type.data);
  useEffect(() => {
    httpClients
      .GET("officetypes", true)
      .then((res) => {
        dispatch(setofficetype(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching office type!",
          status: "error",
        });
      });
  }, [dispatch]);
  const delete_handler = async (delete_id: number) => {
    httpClients
      .DELETE("/officetypes/" + delete_id, true)
      .then((res) => {
        console.log(res);
        dispatch(deleteofficetype(delete_id));
        dispatch(set_modal(false));
        toast({
          title: "Office type has been deleted successfully!",
          status: "success",
        });
      })
      .catch((err) => {
        dispatch(set_modal(false));
        toast({
          title: "Error occured while deleting office type!",
          status: "error",
        });
      });
  };
  const columns = [
    {
      header: "S.N.",
      accessorKey: "id",
    },
    {
      header: "Office Type",
      accessorKey: "office_type",
    },
  ];
  return (
    <>
      <DataTable
        btn
        title="कार्यालय प्रकार"
        actions
        no_view
        delete_handler={delete_handler}
        data={
          office_type &&
          office_type.map((p: any) => {
            return {
              id: p?.id,
              office_type: p?.office_type,
            };
          })
        }
        columns={columns}
      />
    </>
  );
};
export default Office;
