import { useNavigate } from "react-router";
import RoleLayout from ".";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { resetRoles, setrole } from "../../../redux/role";
import { useEffect } from "react";

const RoleCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const role = useAppSelector((state: any) => state.role.specific_role);
  useEffect(() => {
    dispatch(resetRoles());
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    httpClients
      .POST(
        "roles",
        { role: role.role, permissions: Array.from(new Set(role.permissions)) },
        true
      )
      .then((res) => {
        navigate(-1);
        toast({ title: "Roles has been added!", status: "success" });
        httpClients.GET("roles", true).then((res) => {
          dispatch(setrole(res.data.data));
        });
      })
      .catch((err) => {
        toast({ title: "Error occured while sending roles!", status: "error" });
      });
  };
  return (
    <RoleLayout
      title="सिर्जना"
      btn_name="सिर्जना"
      form_handler={form_handler}
    />
  );
};
export default RoleCreate;
