import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../utils/httpClients";
import { DataTable } from "../../component/data_table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_designation, set_modal } from "../../redux/extra_field";

const Designation = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const designation = useAppSelector((state) => state.extraField.designation);
  useEffect(() => {
    httpClients
      .GET("designations", true)
      .then((res) => {
        dispatch(set_designation(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching designation",
          status: "error",
        });
      });
  }, [dispatch]);
  const columns = [
    {
      header: "क्र.स.",
      accessorKey: "id",
    },
    {
      header: "पदनाम",
      accessorKey: "name",
    },
  ];
  const deleteDesignation = async (delete_id: number) => {
    if (delete_id !== undefined && delete_id !== null) {
      httpClients
        .DELETE("designations/" + delete_id, true)
        .then(async (res) => {
          toast({
            title: "Designation has been deleted successfully!",
            status: "success",
          });
          dispatch(set_modal(false));
          await httpClients.GET("designations", true).then((res) => {
            dispatch(set_designation(res.data.data));
          });
        })
        .catch((err) => {
          toast({
            title: "Error occured while deleting designation!",
            status: "error",
          });
          dispatch(set_modal(false));
        });
    }
  };
  return (
    <>
      {designation && (
        <DataTable
          btn
          title="पदनाम"
          actions
          no_view
          nep
          data={
            designation &&
            designation.map((p: any) => {
              return {
                id: p?.id,
                name: p?.designation,
              };
            })
          }
          columns={columns}
          delete_handler={deleteDesignation}
        />
      )}
    </>
  );
};
export default Designation;
