import Select from "react-select";
import style from "./index.module.scss";
interface SelectProps {
  options?: any;
  on_change?: any;
  selectedOption?: object;
  value?: any;
  label?: string;
  placeholder?: string;
  label_class?: string;
  required?: boolean;
  name?: string;
  multi?: boolean;
}
export default function SelectInput(props: SelectProps) {
  return (
    <div className={style.select_input} style={{ width: "100%" }}>
      <p
        className={props.label_class}
        style={{ color: "#000", fontWeight: "500", fontSize: "16px" }}
      >
        {props.label}
        {props.required ? <span style={{ color: "red" }}>*</span> : null}
      </p>
      <Select
        value={props.value}
        defaultValue={props.selectedOption}
        options={props.options}
        onChange={props.on_change}
        required={props.required}
        name={props.name}
        isMulti={props.multi}
        placeholder={props.placeholder || "select options"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            cursor: "pointer",
            fontSize: "16px",
            width: "100%",
            padding: "2px",
            borderColor: "#E2E8F0",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: "#bee3f8",
            primary25: "#bee3f8",
            primary: "#2b6cb0",
          },
        })}
      />
    </div>
  );
}
