import { createSlice } from "@reduxjs/toolkit";
interface VoilenceState {
  data: Array<Object>;
  name: string;
}

const initialState: VoilenceState = {
  data: [],
  name: "",
};

export const voilenceSlice = createSlice({
  name: "voilenceSlice",
  initialState,
  reducers: {
    setvoilence: (state, action) => {
      state.data = action.payload;
    },
    getspecificvoilence: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setvoilence, getspecificvoilence } = voilenceSlice.actions;

export default voilenceSlice.reducer;
