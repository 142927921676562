import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useAppDispatch } from "../../../hooks/redux_hook";
import { useNavigate } from "react-router";
import { addoffice, resetOfficeForm } from "../../../redux/office";
import OfficeLayout from ".";
import { useEffect } from "react";
const OfficeCreate:React.FC = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetOfficeForm());
  }, [dispatch]);
  const office_create = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    console.log(data);
    httpClients
      .POST("/offices", data, true)
      .then((res) => {
        toast({ title: "Office has been created!", status: "success" });
        dispatch(addoffice(res.data.user));
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating office",
          status: "error",
        });
      });
  };
  return (
    <OfficeLayout
      title="सिर्जना"
      btn_name="सिर्जना"
      form_handler={office_create}
      password={true}
    />
  );
};
export default OfficeCreate;
