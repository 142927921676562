import { useNavigate } from "react-router";
import PermissionLayout from ".";
import { useAppDispatch } from "../../../hooks/redux_hook";
import { useEffect } from "react";
import {
  addpermission,
  resetFormPermission,
  setpermission,
} from "../../../redux/permission";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";

const CreatePermission = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();
  useEffect(() => {
    dispatch(resetFormPermission());
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form_data = new FormData(e.target);
    httpClients
      .POST("permissions", form_data, true)
      .then(async (res) => {
        dispatch(addpermission(form_data));
        toast({ title: "Permission has been created!", status: "success" });
        navigate(-1);
        httpClients.GET("permissions", true).then((res) => {
          dispatch(setpermission(res.data.data));
        });
      })
      .catch((err) => {
        toast({
          title: "Error occued while creating permission!",
          status: "error",
        });
      });
  };
  return (
    <PermissionLayout
      title="सिर्जना"
      btn_name="सिर्जना"
      form_handler={form_handler}
    />
  );
};
export default CreatePermission;
