import { createSlice } from "@reduxjs/toolkit";
interface CasteState {
  data: Array<Object>;
  name: string;
}

const initialState: CasteState = {
  data: [],
  name: "",
};

export const casteSlice = createSlice({
  name: "casteSlice",
  initialState,
  reducers: {
    setcaste: (state, action) => {
      state.data = action.payload;
    },
    getspecificcaste: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setcaste, getspecificcaste } = casteSlice.actions;

export default casteSlice.reducer;
