import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../../utils/httpClients";
import { set_district } from "../../../redux/extra_field";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { DataTable } from "../../../component/data_table";
const District = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const district = useAppSelector((state) => state.extraField.district);
  useEffect(() => {
    httpClients
      .GET("districts", true)
      .then((res) => {
        dispatch(set_district(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching districts",
          status: "error",
        });
      });
  }, [dispatch]);
  const columns = [
    {
      header: "क्र.स.",
      accessorKey: "id",
    },
    {
      header: "जिल्ला",
      accessorKey: "name",
    },
  ];
  return (
    <>
      {district && (
        <DataTable
          title="जिल्ला"
          no_view
          actions
          nep
          data={
            district &&
            district
              .filter((p: any) => p?.province?.id === 6)
              ?.map((p: any) => {
                return {
                  id: p?.id,
                  name: p?.district_np,
                };
              })
          }
          columns={columns}
        />
      )}
    </>
  );
};
export default District;
