import { Stack, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../utils/httpClients";
import { DataTable } from "../../component/data_table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_modal } from "../../redux/extra_field";
import { setfiscalyear } from "../../redux/fiscalyear";

const FiscalYear = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const fiscalyear = useAppSelector((state) => state.fiscalyear.data);
  useEffect(() => {
    httpClients
      .GET("fiscalyears", true)
      .then((res) => {
        dispatch(setfiscalyear(res.data.data));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching fiscal year",
          status: "error",
        });
      });
  }, [dispatch]);
  const columns = [
    {
      header: "क्र.स.",
      accessorKey: "id",
    },
    {
      header: "आर्थिक वर्ष",
      accessorKey: "name",
    },
  ];
  const deleteFiscalYear = async (delete_id: number) => {
    if (delete_id !== undefined && delete_id !== null) {
      httpClients
        .DELETE("fiscalyears/" + delete_id, true)
        .then(async (res) => {
          toast({
            title: "Fiscal year has been deleted successfully!",
            status: "success",
          });
          dispatch(set_modal(false));
          await httpClients.GET("fiscalyears", true).then((res) => {
            dispatch(setfiscalyear(res.data.data));
          });
        })
        .catch((err) => {
          toast({
            title: "Error occured while deleting fiscal year!",
            status: "error",
          });
          dispatch(set_modal(false));
        });
    }
  };
  return (
    <>
      <DataTable
        btn
        title="आर्थिक वर्ष"
        actions
        no_view
        nep
        data={
          fiscalyear &&
          fiscalyear.map((p: any) => {
            return {
              id: p?.id,
              name: p?.name,
            };
          })
        }
        columns={columns}
        delete_handler={deleteFiscalYear}
      />
    </>
  );
};
export default FiscalYear;
