import * as React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Flex,
  TableContainer,
  Button,
  Card,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Select,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { BiMessageSquareAdd, BiSearchAlt } from "react-icons/bi";
import { useNavigate } from "react-router";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useAppDispatch } from "../../hooks/redux_hook";
import { AiOutlineCloudDownload, AiOutlineEye } from "react-icons/ai";
import { HiPencil } from "react-icons/hi";
import { CiCircleRemove } from "react-icons/ci";
import DeleteModal from "../modal/delete";
import { set_modal } from "../../redux/extra_field";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  actions?: boolean;
  btn?: boolean;
  title?: string;
  btn_name?: string;
  redirect_url?: string;
  push_back?: boolean;
  delete_handler?: any;
  download?: boolean;
  nep?: boolean;
  no_view?: boolean;
  docs?: any;
};

export function DataTable<Data extends object>({
  data,
  columns,
  actions,
  btn,
  title,
  btn_name,
  redirect_url,
  push_back,
  delete_handler,
  download,
  nep,
  no_view,
  docs,
}: DataTableProps<Data>) {
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "case_table",
    sheet: "Users",
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [delete_id, setDeleteId] = React.useState<number | null>(null);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
    state: {
      sorting,
      globalFilter,
    },
  });

  const deleteHandler = (id: number) => {
    setDeleteId(id);
    dispatch(set_modal(true));
  };
  return (
    <>
      {download && (
        <button className="file_button" onClick={onDownload}>
          <AiOutlineCloudDownload />
          डाउनलोड तालिका
        </button>
      )}

      <Card p={4}>
        <Flex
          justifyContent={"space-between"}
          direction={btn ? "column" : "row"}
        >
          <Text fontSize={28} fontWeight={600} textAlign={"start"} p={"10px 0"}>
            {title}
          </Text>
          {btn && <Divider />}
          <Flex
            width={btn ? "100%" : "40%"}
            gap={5}
            p={"10px 0"}
            justifyContent={"space-between"}
          >
            <InputGroup width={btn ? "40%" : "100%"} justifySelf={"flex-end"}>
              <InputLeftElement
                pointerEvents="none"
                children={<BiSearchAlt />}
              />
              <Input
                placeholder="डेटा खोजी गर्नुहोस्"
                value={globalFilter ?? ""}
                onChange={(event) => setGlobalFilter(event.target.value)}
              />
            </InputGroup>
            {btn && (
              <Flex gap={3}>
                <Button
                  leftIcon={<BiMessageSquareAdd />}
                  onClick={() => navigate("create")}
                  colorScheme="blue"
                >
                  डेटा थप्नुहोस्
                </Button>
                {table.getPageCount() > 1 && (
                  <Box width="10rem">
                    <Select
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </Select>
                  </Box>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        <Divider />
        <TableContainer pt={4} ref={tableRef}>
          <Table border={"1px solid #e5e9eb"}>
            <Thead>
              {table.getHeaderGroups().map((headerGroup: any) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => {
                    const meta: any = header.column.columnDef.meta;
                    return (
                      <Th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        isNumeric={meta?.isNumeric}
                        fontSize={15}
                        fontWeight={600}
                        color={"black"}
                        cursor={"pointer"}
                        backgroundColor="#f1f1f1"
                        letterSpacing={"0px"}
                      >
                        <Flex
                          justifyContent={
                            meta?.isNumeric ? "flex-end" : "flex-start"
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                          <chakra.span>
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <IoMdArrowDropdown aria-label="sorted descending" />
                              ) : (
                                <IoMdArrowDropup aria-label="sorted ascending" />
                              )
                            ) : null}
                          </chakra.span>
                        </Flex>
                      </Th>
                    );
                  })}
                  {docs && (
                    <Td
                      fontSize={16}
                      textTransform={"uppercase"}
                      fontWeight={600}
                      color={"black.500"}
                      backgroundColor="#f1f1f1"
                      pt={2}
                      pb={2}
                    >
                      कागजात
                    </Td>
                  )}
                  {actions && (
                    <Td
                      fontSize={16}
                      textTransform={"uppercase"}
                      fontWeight={600}
                      color={"black.500"}
                      backgroundColor="#f1f1f1"
                      pt={2}
                      pb={2}
                    >
                      {nep ? "कार्य" : "Actions"}
                    </Td>
                  )}
                </Tr>
              ))}
            </Thead>
            <Tbody className="table">
              {table.getRowModel().rows.map((row: any) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell: any) => {
                    const meta: any = cell.column.columnDef.meta;
                    return (
                      <Td key={cell.id} isNumeric={meta?.isNumeric}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                  {docs && (
                    <Td
                      fontWeight={500}
                      color={"blue.800"}
                      textDecoration={"underline"}
                    >
                      <a
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BASE_URL?.replace(
                            "api",
                            "storage"
                          ) + row.original.case_file_path
                        }
                        style={{ height: "21px" }}
                        target="_blank"
                      >
                        कागजात लिङ्क
                      </a>
                    </Td>
                  )}

                  {actions && (
                    <Td fontSize={20}>
                      <Flex gap={2}>
                        {!no_view && (
                          <Tooltip
                            label="दृश्य"
                            bg="blue.600"
                            hasArrow
                            color="white"
                            aria-label="A tooltip"
                          >
                            <p
                              onClick={() =>
                                navigate("view/" + row?.original?.id)
                              }
                            >
                              <AiOutlineEye className="primary_hover" />
                            </p>
                          </Tooltip>
                        )}
                        <Tooltip
                          label="सम्पादन"
                          bg="blue.600"
                          hasArrow
                          color="white"
                          aria-label="A tooltip"
                        >
                          <p
                            onClick={() =>
                              navigate("update/" + row?.original?.id)
                            }
                          >
                            <HiPencil className="primary_hover" />
                          </p>
                        </Tooltip>
                        <Tooltip
                          label="मेट्नुहोस्"
                          bg="red.600"
                          color="white"
                          hasArrow
                          aria-label="A tooltip"
                        >
                          <p>
                            <CiCircleRemove
                              className="error_hover"
                              onClick={() => deleteHandler(row?.original?.id)}
                            />
                          </p>
                        </Tooltip>
                      </Flex>
                    </Td>
                  )}
                </Tr>
              ))}
              {table.getRowModel().rows.length < 1 && (
                <Td
                  colSpan={table?.getHeaderGroups()[0]?.headers?.length + 1}
                  textAlign={"center"}
                  color={"red.600"}
                >
                  No data has been found{" "}
                  {globalFilter && <span>with keyword "{globalFilter}"</span>}.
                </Td>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        {table.getPageCount() > 1 && (
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Box>
              <Text>
                Page {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </Text>
            </Box>
            <Flex alignItems={"center"} gap={2} p={"10px 0"}>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => table.setPageIndex(0)}
                isDisabled={!table.getCanPreviousPage()}
                fontSize={22}
                p={2}
              >
                <MdKeyboardDoubleArrowLeft />
              </Button>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => table.previousPage()}
                isDisabled={!table.getCanPreviousPage()}
                fontSize={22}
                p={2}
              >
                <MdKeyboardArrowLeft />
              </Button>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => table.nextPage()}
                isDisabled={!table.getCanNextPage()}
                fontSize={22}
                p={2}
              >
                <MdKeyboardArrowRight />
              </Button>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                isDisabled={!table.getCanNextPage()}
                fontSize={22}
                p={2}
              >
                <MdKeyboardDoubleArrowRight />
              </Button>
            </Flex>
          </Flex>
        )}
      </Card>
      <DeleteModal delete_handler={() => delete_handler(delete_id)} />
    </>
  );
}
