const FieldSet = (props: {
  children?: JSX.Element[] | JSX.Element;
  title: string;
}) => {
  return (
    <fieldset
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "#fff",
      }}
    >
      <legend
        className="bold"
        style={{
          color: "#1f60ba",
          fontSize: "1rem",
          padding: "0 8px",
          fontWeight: "500",
        }}
      >
        {props.title}
      </legend>
      {props.children}
    </fieldset>
  );
};
export default FieldSet;
