import { useEffect } from "react";
import CaseForm from "./create";
import { useAppDispatch } from "../../../hooks/redux_hook";
import { reset_case_form, set_status } from "../../../redux/case";
import { set_tab_id } from "../../../redux/tabs";
import { remove_checks } from "../../../redux/tabs";
const CreateCaseForm = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(set_status("create"));
    dispatch(reset_case_form());
    dispatch(set_tab_id(0));
    dispatch(remove_checks());
  }, [dispatch]);
  return <CaseForm />;
};
export default CreateCaseForm;
