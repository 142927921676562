import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../utils/httpClients";
import { DataTable } from "../../component/data_table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_modal } from "../../redux/extra_field";
import { deleteoffice, setoffice } from "../../redux/office";

const Office = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const office = useAppSelector((state) => state.office.data);
  useEffect(() => {
    httpClients
      .GET("offices", true)
      .then((res) => {
        dispatch(setoffice(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching office!",
          status: "error",
        });
      });
  }, [dispatch]);
  const delete_handler = async (delete_id: number) => {
    httpClients
      .DELETE("/offices/" + delete_id, true)
      .then((res) => {
        console.log(res);
        dispatch(deleteoffice(delete_id));
        dispatch(set_modal(false));
        toast({
          title: "Office data has been deleted successfully!",
          status: "success",
        });
      })
      .catch((err) => {
        dispatch(set_modal(false));
        toast({
          title: "Error occured while deleting office!",
          status: "error",
        });
      });
  };
  const columns = [
    {
      header: "S.N.",
      accessorKey: "id",
    },
    {
      header: "Office Type",
      accessorKey: "office_type",
    },
    {
      header: "Name/Dept",
      accessorKey: "name",
    },
    {
      header: "Address",
      accessorKey: "address",
    },
    {
      header: "Contact",
      accessorKey: "contact",
    },
    {
      header: "Status",
      accessorKey: "active_status",
    },
    {
      header: "Local Level",
      accessorKey: "llevel",
    },
  ];
  return (
    <>
      <DataTable
        btn
        title="कार्यालय"
        actions
        delete_handler={delete_handler}
        data={
          office &&
          office.map((p: any) => {
            return {
              id: p?.id,
              office_type: p?.office_type?.office_type,
              name: p?.office_name,
              address: p?.office_address,
              contact: p?.office_phone,
              active_status: p?.active_status ? "Active" : "In Active",
              llevel: p?.locallevel?.lgname_np,
            };
          })
        }
        columns={columns}
      />
    </>
  );
};
export default Office;
