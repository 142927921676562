import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import httpClients from "../../../utils/httpClients";
import { set_llevel } from "../../../redux/extra_field";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { DataTable } from "../../../component/data_table";
const LocalLevel = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const llevel = useAppSelector((state) => state.extraField.llevel);
  useEffect(() => {
    httpClients
      .GET("llevels", true)
      .then((res) => {
        dispatch(set_llevel(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching llevel",
          status: "error",
        });
      });
  }, [dispatch]);
  const columns = [
    {
      header: "क्र.स.",
      accessorKey: "id",
    },
    {
      header: "प्रदेश",
      accessorKey: "province",
    },
    {
      header: "जिल्ला",
      accessorKey: "district",
    },
    {
      header: "स्थानीय तह",
      accessorKey: "name",
    },
  ];
  return (
    <>
      {llevel && (
        <DataTable
          title="स्थानीय तह"
          no_view
          actions
          nep
          data={
            llevel &&
            llevel
              ?.filter((p: any) => p?.province?.id === 6)
              ?.map((p: any) => {
                return {
                  id: p?.id,
                  province: p?.province.province_np,
                  district: p?.district.district_np,
                  name: p?.lgname_np,
                };
              })
          }
          columns={columns}
        />
      )}
    </>
  );
};
export default LocalLevel;
