import {
  Box,
  Card,
  Divider,
  Flex,
  Progress,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import SearchBar from "../case_report_module/search_bar";
import DashboardSummaryCard from "../../component/dashboardcomponent";
import {
  BsGenderFemale,
  BsListCheck,
  BsGenderMale,
  BsGenderAmbiguous,
} from "react-icons/bs";
import { useAppSelector } from "../../hooks/redux_hook";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { set_reports } from "../../redux/reports";
import httpClients from "../../utils/httpClients";
import { reset_search_data } from "../../redux/search_bar";
import { reset_report } from "../../api/reset_report";
import { changeIndexToNepali } from "../../hooks/number_translation";
import GenderChart from "../../component/chart/GenderChart";
import { Graph1 } from "../../component/graphs";
import RadialChart from "../../component/graphs/graph2";

const Dashboard = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const fiscal_year_id = useAppSelector(
    (state) => state.search_bar.fiscal_year_id
  );
  const province_id = useAppSelector((state) => state.search_bar.provinces);
  const district_id = useAppSelector((state) => state.search_bar.district);
  const llevel_id = useAppSelector((state) => state.search_bar.llevel);
  const reports = useAppSelector((state) => state.report.all_reports);

  const api_call = async () => {
    await httpClients
      .GET(
        `allreports?fiscal_year_id=${fiscal_year_id}${
          province_id ? "&" : "?"
        }province_id=${province_id}${
          province_id ? "&" : "?"
        }district_id=${district_id}${
          province_id ? "&" : "?"
        }llevel_id=${llevel_id}`,
        true
      )
      .then((res) => {
        dispatch(set_reports(res.data));
        console.log(res.data.yearlyreport);
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching reports data",
          status: "error",
        });
      });
  };
  useEffect(() => {
    console.log("dashboard");
    dispatch(reset_search_data());
    api_call();
  }, [dispatch]);
  const reset_data = () => {
    dispatch(reset_search_data());
    reset_report();
  };

  return (
    <>
      <Flex direction="column" gap={5}>
        <SearchBar search_handler={api_call} ward reset_btn={reset_data} />

        <Flex direction="row" justifyContent={"space-between"}>
          <DashboardSummaryCard
            number={
              reports?.yearlyreport.gender["महिला"] +
              reports?.yearlyreport.gender["पुरुष"] +
              reports?.yearlyreport.gender["अन्य"]
            }
            text="जम्मा केस दर्ता"
            gender={<BsListCheck />}
            bgColor="green.100"
            icon_color="green.400"
          />
          <DashboardSummaryCard
            number={reports?.yearlyreport.gender["महिला"]}
            text="महिला संख्या"
            gender={<BsGenderFemale />}
            bgColor="red.100"
            icon_color="red.400"
          />
          <DashboardSummaryCard
            number={reports?.yearlyreport.gender["पुरुष"]}
            text="पुरुष संख्या"
            gender={<BsGenderMale />}
            bgColor="blue.100"
            icon_color="blue.400"
          />
          <DashboardSummaryCard
            number={reports?.yearlyreport.gender["अन्य"]}
            text="अन्य संख्या"
            gender={<BsGenderAmbiguous />}
            bgColor="yellow.100"
            icon_color="orange.400"
          />
        </Flex>
        <Flex direction={"row"} gap={4} width={"100%"}>
          <Card width={"50%"}>
            <Text fontSize={25} p={4} fontWeight={500}>
              लैङ्गिक चार्ट
            </Text>
            <Divider />
            <GenderChart data={reports?.yearlyreport.gender} />
          </Card>

          <Card width={"50%"}>
            <Text fontSize={25} p={4} fontWeight={500}>
              उमेर चार्ट
            </Text>
            <Divider />
            <Stack spacing={5} p={4}>
              {reports &&
                Object.entries(reports?.yearlyreport.age).map(
                  ([key, value]) => (
                    <div key={key}>
                      <Text>
                        {key}
                        &nbsp;
                        <Text as="span" fontWeight={600}>
                          {value as number}
                        </Text>
                      </Text>
                      <Progress
                        colorScheme="blue"
                        size="sm"
                        value={value as number}
                      />
                    </div>
                  )
                )}
            </Stack>
          </Card>
        </Flex>
        <Flex direction={"row"} gap={4} width={"100%"}>
          <Card width={"50%"}>
            <Text fontSize={25} p={4} fontWeight={500}>
              प्रेषण गरी पठाएको निकाय र सेवा
            </Text>
            <Divider />
            {reports && (
              <RadialChart
                labels={Object.keys(
                  reports?.yearlyreport.affected_service_center
                )}
                series={Object.values(
                  reports?.yearlyreport.affected_service_center
                )}
              />
            )}
          </Card>
          <Card width={"50%"}>
            <Text fontSize={25} p={4} fontWeight={500}>
              प्रेषण गरी पठाउने निकाय र संस्था
            </Text>
            <Divider />
            <TableContainer>
              <Table>
                <Thead>
                  <Th fontSize={13}>क्र.स.</Th>
                  <Th fontSize={13}>नाम</Th>
                  <Th fontSize={13}>संख्या</Th>
                </Thead>
                <Tbody>
                  {reports &&
                    Object.entries(reports?.yearlyreport.affected_victim).map(
                      ([key, value]: [string, unknown], index: number) => (
                        <Tr key={key}>
                          <Td>{index + 1}</Td>
                          <Td>{key}</Td>
                          <Td>{value as any}</Td>
                        </Tr>
                      )
                    )}
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
        </Flex>
        <Card width={"100%"}>
          <Text fontSize={25} p={4} fontWeight={500}>
            प्रेषण गरी पठाएको निकाय र सेवा
          </Text>
          <Divider />
          <TableContainer>
            <Table>
              <Thead>
                <Th fontSize={13}>क्र.स.</Th>
                <Th fontSize={13}>नाम</Th>
                <Th fontSize={13}>संख्या</Th>
              </Thead>
              <Tbody>
                {reports &&
                  Object.entries(reports?.yearlyreport.others).map(
                    ([key, value]: [string, any], index: number) => (
                      <Tr key={key}>
                        <Td>{index + 1}</Td>
                        <Td>{key}</Td>
                        <Td>{value["भएको"] as any}</Td>
                      </Tr>
                    )
                  )}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Card>
          <Text fontSize={25} p={4} fontWeight={500}>
            हिंसाका प्रकार
          </Text>
          <Divider />
          <Box padding={"0px 12px"}>
            <Graph1 data={reports?.yearlyreport.voilence_type} />
          </Box>
        </Card>
      </Flex>
    </>
  );
};
export default Dashboard;
