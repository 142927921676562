import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Stack,
  Flex,
  Box,
} from "@chakra-ui/react";
import "./index.scss";
import { BiChevronRight } from "react-icons/bi";
import NavBar from "./Navbar";
import SideBar from "./sidebar";
import { useAppSelector } from "../../hooks/redux_hook";
import { useLocation, useNavigate } from "react-router";
const AdminPanel = (props: { children?: JSX.Element[] | JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const sidebar = useAppSelector((state) => state.sidebar.sidebar);
  let paths = location.pathname.split("/").splice(1);
  const navigationHandler = (index: number) => {
    let p = paths.slice(0, index + 1);
    navigate("/" + p.join("/"));
  };
  return (
    <>
      <NavBar />
      <Stack
        className="body_items"
        direction={"row"}
        alignItems={"flex-start"}
        width={"100%"}
      >
        <SideBar />
        <Flex
          direction="column"
          flex={1}
          margin={"0 !important"}
          height="100%"
          width={"100%"}
          backgroundColor="#f1f1f1"
        >
          <Center
            justifyContent={"flex-start"}
            borderBottom={"1px solid #ccc"}
            height="2.5rem"
            className={"sub_sidebar"}
            ml={sidebar ? "304px" : "82.54px"}
          >
            <Breadcrumb
              spacing="8px"
              separator={<BiChevronRight color="gray.500" />}
              marginLeft={"10px"}
            >
              <BreadcrumbItem>
                <BreadcrumbLink onClick={() => navigate("/")}>
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              {paths.length > 1 ? (
                paths?.map((p, i) => (
                  <BreadcrumbItem
                    onClick={() => navigationHandler(i)}
                    textTransform={"capitalize"}
                  >
                    <BreadcrumbLink>{p.split("_").join(" ")}</BreadcrumbLink>
                  </BreadcrumbItem>
                ))
              ) : (
                <BreadcrumbItem textTransform={"capitalize"}>
                  <BreadcrumbLink>
                    {paths[0].split("_").join(" ")}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
            </Breadcrumb>
          </Center>
          <Box
            marginLeft={sidebar ? "304px" : "82.54px"}
            boxSizing="border-box"
            padding={"20px 0"}
            mt={"147.8px"}
            height={"max-content"}
            backgroundColor="#f1f1f1"
          >
            <Box
              margin="0px auto"
              height={"100%"}
              width="95%"
              backgroundColor="#f1f1f1"
            >
              {props.children}
            </Box>
          </Box>
        </Flex>
      </Stack>
    </>
  );
};
export default AdminPanel;
