import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import { ReactComponent as YourSvg } from "../../assets/error_page.svg";
import { AiOutlineArrowLeft } from "react-icons/ai";
import style from "./index.module.scss";
import { useNavigate } from "react-router";
const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <Stack
      position={"relative"}
      width={"100%"}
      height={"100%"}
      backgroundColor="#fff"
    >
      <Box width="51%" margin={"0 auto"}>
        <YourSvg />
        <Stack className={style.position_ele} direction={"column"} gap={3}>
          <Stack direction={"column"} gap={1}>
            <Heading textAlign={"center"}>Something goes wrong...</Heading>
            <Text textAlign={"center"} fontSize={18}>
              तपाईँले खोजिरहेको पृष्ठ अवस्थित छैन!
            </Text>
          </Stack>
          <Button
            width={"max-content"}
            alignSelf={"center"}
            leftIcon={<AiOutlineArrowLeft />}
            colorScheme="blue"
            variant="solid"
            type="submit"
            onClick={() => navigate("/")}
          >
            Go to HomePage
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
export default ErrorPage;
