import { FormEvent, useEffect } from "react";
import UserLayout from ".";
import httpClients from "../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { getSpecificuser, setUser, updateuser } from "../../../redux/user";
import { useAppDispatch } from "../../../hooks/redux_hook";
import { useToast } from "@chakra-ui/react";

const UpdateUser = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    httpClients
      .GET("users/" + id, true)
      .then((res) => {
        let data = res.data.data;
        dispatch(
          getSpecificuser({
            ...data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error occured while fetching user's data!",
          status: "error",
        });
      });
  }, [id, dispatch]);
  const update_user = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    if (id !== undefined) {
      httpClients
        .PUT("users/" + id, data, true)
        .then(async (res) => {
          console.log(res);
          dispatch(updateuser(res.data.user));
          toast({ title: "User has been updated!", status: "success" });
          await httpClients.GET("users", true).then((res) => {
            dispatch(setUser(res.data.data));
          });
          navigate(-1);
        })
        .catch((err) => {
          toast({
            title: "Error occured while updating user",
            status: "error",
          });
        });
    }
  };

  return (
    <UserLayout
      title="सम्पादन"
      btn_name="सम्पादन"
      form_handler={update_user}
      password={false}
    />
  );
};
export default UpdateUser;
