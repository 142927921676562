import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import ComponentWrapper from "../../../component/component_wrapper";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { getspecificservices } from "../../../redux/affected_services";

const AffectedServicesLayout = (props: {
  title?: string;
  btn_name?: string;
  form_handler?: any;
}) => {
  const dispatch = useAppDispatch();
  const name = useAppSelector((state: any) => state.affectedservices.name);
  const input_change = (e: any) => {
    dispatch(getspecificservices(e.target.value));
  };
  return (
    <ComponentWrapper
      title={"प्रभावित सेवा " + props.title}
      action_btn={props.btn_name}
      form_submit_handler={props.form_handler}
      width="50%"
      icon={<AiOutlineUserAdd />}
    >
      <FormControl isRequired>
        <FormLabel p={0} m={0}>
          प्रभावित सेवा
        </FormLabel>
        <InputGroup>
          <Input
            fontWeight="500"
            type="text"
            name="name"
            placeholder="प्रभावित सेवा प्रविष्ट गर्नुहोस्"
            value={name}
            onChange={input_change}
          />
        </InputGroup>
      </FormControl>
    </ComponentWrapper>
  );
};
export default AffectedServicesLayout;
