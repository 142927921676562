import React from "react";
import {
  Flex,
  Card,
  CardBody,
  Text,
  Box,
  Circle,
} from "@chakra-ui/react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

type Props = {
  number: string | undefined;
  text: string;
  gender: ReactJSXElement;
  bgColor?: string;
  icon_color?: string;
};

const DashboardSummaryCard = (props: Props) => {
  const { number, text, gender } = props;
  return (
    <Card width="24%">
      <CardBody>
        <Flex direction={"row"} gap={4} alignItems={"center"}>
          <Circle size="70px" bg={props.bgColor} color={props.icon_color}>
            <span style={{ fontSize: "24px" }}>{gender}</span>
          </Circle>
          <Box>
            <Text fontWeight={500} color={"blackAlpha.600"}>
              {text}
            </Text>
            <Text fontSize={40} fontWeight={500}>{number}</Text>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default DashboardSummaryCard;
