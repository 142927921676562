import GenderLayout from ".";
import React from "react";
import { getspecificgender, setgender } from "../../../redux/gender";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
const UpdateGender = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const name = useAppSelector((state) => state.gender.name);
  React.useEffect(() => {
    httpClients
      .GET("genders/" + id, true)
      .then((res) => {
        dispatch(getspecificgender(res.data.data.name));
      })
      .catch((err) => {
        toast({
          title: "Error occured while fetching gender data!",
          status: "error",
        });
      });
  }, [dispatch]);
  const updategender = (e: any) => {
    e.preventDefault();
    httpClients
      .PUT(
        "genders/" + id,
        {
          name,
        },
        true
      )
      .then((res) => {
        toast({ title: "Gender has been updated!", status: "success" });
        httpClients.GET("genders", true).then((res) => {
          dispatch(setgender(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating gender!",
          status: "error",
        });
      });
  };
  return (
    <GenderLayout
      form_handler={updategender}
      btn_name="सम्पादन"
      title="सम्पादन"
    />
  );
};
export default UpdateGender;
