import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Text,
} from "@chakra-ui/react";
import "../index.scss";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import { useNavigate } from "react-router";
import style from "./index.module.scss";
import LinkWrapper from "./link";
import { BiCalendarAlt, BiChair, BiLock } from "react-icons/bi";
import {
  MdKeyboardDoubleArrowLeft,
  MdOutlinePersonalInjury,
} from "react-icons/md";
import { set_sidebar } from "../../../redux/sidebar";
import { BsCalendar3, BsGrid } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { IoKey } from "react-icons/io5";
import { TbAddressBook, TbAmbulance, TbReportAnalytics } from "react-icons/tb";
import {
  RiFolderUserLine,
  RiGovernmentLine,
  RiMapPinUserLine,
  RiUserVoiceLine,
} from "react-icons/ri";
import SubLink from "./sub_link";
import { FaUserInjured } from "react-icons/fa";
import { GiMetalHand, GiPostOffice } from "react-icons/gi";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
const SideBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [current_state, setCurrentState] = useState<number>(0);
  const sidebar = useAppSelector((state) => state.sidebar.sidebar);
  const access = useAppSelector((state) => state.login.access);
  const toggleSidebar = () => {
    dispatch(set_sidebar());
  };
  const link_handler = (index: number, url?: string) => {
    setCurrentState(index);
    if (url) {
      navigate(url);
    }
  };
  return (
    <Accordion
      marginTop="0 !important"
      borderRight="1px solid #ccc"
      allowToggle
      width={sidebar ? "19rem" : "max-content"}
      className={style.sidebar}
    >
      <AccordionItem mb={1} borderStyle={"none"} className="accordion_button">
        <AccordionButton
          height="2.5rem"
          borderTop="none"
          className={style.icon_align_right}
          onClick={toggleSidebar}
        >
          <Text>
            <MdKeyboardDoubleArrowLeft
              style={{
                rotate: sidebar ? "0deg" : "180deg",
                transition: "all .2s ease-in-out",
              }}
            />
          </Text>
        </AccordionButton>
      </AccordionItem>

      <LinkWrapper
        icon={<BsGrid />}
        title="गृहपृष्ठ"
        click_handler={() => link_handler(0, "/")}
        active={current_state === 0}
      />
      <LinkWrapper
        title="संघीय संरचना"
        click_handler={() => link_handler(10)}
        active={current_state === 10}
        icon={<RiGovernmentLine />}
      >
        <SubLink title="प्रदेश" url="/federal_sturcture/province" />
        <SubLink
          title="जिल्ला
"
          url="/federal_sturcture/district"
        />
        <SubLink
          title="स्थानीय तह
"
          url="/federal_sturcture/llevel"
        />
      </LinkWrapper>
      <LinkWrapper
        title="प्रयोगकर्ता कन्फिगरेसन"
        icon={<FiUser />}
        click_handler={() => link_handler(4)}
        active={current_state === 4}
      >
        {access?.includes("manage-user") && (
          <SubLink
            title="प्रयोगकर्ता"
            url="/user_configuration/user"
            icon={<FiUser />}
          />
        )}
        {access?.includes("manage-role") && (
          <SubLink
            title="भूमिका"
            url="/user_configuration/role"
            icon={<IoKey />}
          />
        )}
        {access?.includes("manage-permission") && (
          <SubLink
            title="अनुमति"
            url="/user_configuration/permission"
            icon={<BiLock />}
          />
        )}
        <SubLink
          title="पद"
          url="/user_configuration/designation"
          icon={<BiChair />}
        />
      </LinkWrapper>
      <LinkWrapper
        title="प्रणाली कन्फिगरेसन"
        icon={<AiOutlineSetting />}
        click_handler={() => link_handler(5)}
        active={current_state === 5}
      >
        <SubLink
          title="कार्यालय"
          url="system_configuration/office"
          icon={<HiOutlineBuildingOffice2 />}
        />
        <SubLink
          title="कार्यालय प्रकार"
          url="system_configuration/office_type"
          icon={<GiPostOffice />}
        />
        <SubLink
          title="आर्थिक वर्ष"
          url="system_configuration/fiscal_year"
          icon={<BiCalendarAlt />}
        />
        <SubLink
          title="लिङ्ग"
          url="system_configuration/genders"
          icon={<RiFolderUserLine />}
        />
        <SubLink
          title="जातजाति"
          url="system_configuration/caste_tribes"
          icon={<RiMapPinUserLine />}
        />
        <SubLink
          title="हिंसा"
          url="system_configuration/voilence_types"
          icon={<GiMetalHand />}
        />
        <SubLink
          title="अपाङ्गता"
          icon={<MdOutlinePersonalInjury />}
          url="system_configuration/disabilities"
        />
        <SubLink
          title="प्रभावित सेवा"
          url="system_configuration/affected_services"
          icon={<TbAmbulance />}
        />
        <SubLink
          title="प्रभावित पीडित"
          url="system_configuration/affected_victims"
          icon={<FaUserInjured />}
        />
      </LinkWrapper>
      <LinkWrapper
        title="केस रिपोर्ट मोड्युल"
        icon={<TbAddressBook />}
        click_handler={() => link_handler(6)}
        active={current_state === 6}
      >
        <SubLink
          title="केस"
          url="case_report/case"
          icon={<RiUserVoiceLine />}
        />
        <SubLink
          title="रिपोर्ट"
          url="case_report/report"
          icon={<TbReportAnalytics />}
        />
      </LinkWrapper>
    </Accordion>
  );
};
export default SideBar;
