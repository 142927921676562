import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_hook";
import httpClients from "../../../utils/httpClients";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { getspecificservices, setaffectedservices } from "../../../redux/affected_services";
import AffectedServicesLayout from ".";
const CreateAffectedServices = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.affectedservices.name);
  React.useEffect(() => {
    dispatch(getspecificservices(""));
  }, [dispatch]);
  const createcaste = (e: any) => {
    e.preventDefault();
    httpClients
      .POST("affectedservices", {name}, true)
      .then((res) => {
        toast({
          title: "Affected services has been created!",
          status: "success",
        });
        httpClients.GET("affectedservices", true).then((res) => {
          dispatch(setaffectedservices(res.data.data));
        });
        navigate(-1);
      })
      .catch((err) => {
        toast({
          title: "Error occured while creating affected services!",
          status: "error",
        });
      });
  };
  return (
    <AffectedServicesLayout
      form_handler={createcaste}
      btn_name="थप्नुहोस्"
      title="थप्नुहोस्"
    />
  );
};
export default CreateAffectedServices;
