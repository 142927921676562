import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import SelectInput from "../../../../component/input/input_select";
import { useEffect } from "react";
import httpClients from "../../../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_hook";
import TabButton from "../../../../component/button/tab_button";
import { check_tab, set_tab_id } from "../../../../redux/tabs";
import { set_province } from "../../../../redux/extra_field";
import { set_llevel } from "../../../../redux/extra_field";
import { set_district } from "../../../../redux/extra_field";
import React from "react";
import { setgender } from "../../../../redux/gender";
import { setcaste } from "../../../../redux/caste_tribes";
import { setdisability } from "../../../../redux/disability";
import { setvoilence } from "../../../../redux/voilence";
import { setfiscalyear } from "../../../../redux/fiscalyear";
import { set_specific_field } from "../../../../redux/case";
const IncidentData = () => {
  const dispatch = useAppDispatch();
  const gender = useAppSelector((state) => state.gender.data);
  const castetribes = useAppSelector((state) => state.caste.data);
  const disabilites = useAppSelector((state) => state.disability.data);
  const voiliences = useAppSelector((state) => state.voilence.data);
  const fiscal_year = useAppSelector((state) => state.fiscalyear.data);
  const provinces = useAppSelector((state) => state.extraField.provinces);
  const districts = useAppSelector((state) => state.extraField.district);
  const llevel = useAppSelector((state) => state.extraField.llevel);
  const data = useAppSelector((state) => state.case.data);
  useEffect(() => {
    httpClients.GET("genders", true).then((res) => {
      console.log(res.data.data);
      dispatch(setgender(res.data.data));
    });
    httpClients.GET("castetribes", true).then((res) => {
      console.log("casete", res.data.data);
      dispatch(setcaste(res.data.data));
    });
    httpClients.GET("disabilities", true).then((res) => {
      dispatch(setdisability(res.data.data));
    });
    httpClients.GET("violencetypes", true).then((res) => {
      dispatch(setvoilence(res.data.data));
    });
    httpClients.GET("fiscalyears", true).then((res) => {
      dispatch(setfiscalyear(res.data.data));
    });
    httpClients.GET("provinces", true).then((res) => {
      dispatch(set_province(res.data.data));
    });
    httpClients.GET("districts", true).then((res) => {
      dispatch(set_district(res.data.data));
    });
    httpClients.GET("llevels", true).then((res) => {
      dispatch(set_llevel(res.data.data));
    });
  }, [dispatch]);
  const onSubmit = (e: any) => {
    e.preventDefault();
    dispatch(set_tab_id(1));
    dispatch(check_tab(0));
  };
  const changeHandler = (e: any) => {
    dispatch(
      set_specific_field({ name: e.target.name, value: e.target.value })
    );
  };
  const select_change = (name: string, value: any) => {
    dispatch(set_specific_field({ name, value }));
  };
  const today = new Date().toISOString().split("T")[0];
  const mydate1 = document.getElementById("myDate1") as any;
  if (mydate1 !== null) {
    mydate1.max = today;
  }
  return (
    <form onSubmit={onSubmit}>
      <Flex direction={"column"} gap={5} p={4}>
        <Flex gap={4}>
          <FormControl isRequired>
            <FormLabel>पिडित/ प्रभावितको नाम</FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                placeholder="नाम"
                name="afflicted_name"
                value={data?.afflicted_name}
                onChange={changeHandler}
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>घटना दर्ता मिति</FormLabel>
            <InputGroup>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
                name="incident_report_date"
                value={data?.incident_report_date as any}
                onChange={changeHandler}
                id="myDate1"
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <SelectInput
            placeholder="आर्थिक वर्ष"
            label="आर्थिक वर्ष"
            required
            options={
              fiscal_year &&
              fiscal_year.map((p: any) => {
                return { label: p.name, value: p.id };
              })
            }
            on_change={(e: any) => select_change("fiscal_year_id", e.value)}
            value={
              fiscal_year &&
              fiscal_year
                .map((p: any) => {
                  return { label: p.name, value: p.id };
                })
                .filter(
                  (p: any) =>
                    p.value.toString() === data?.fiscal_year_id.toString()
                )
            }
            name="fiscal_year_id"
          />
          <FormControl isRequired>
            <FormLabel>पहिलो भ्रमण वा फलोअप</FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                name="first_followup"
                placeholder="पहिलो फलोअप"
                value={data?.first_followup}
                onChange={changeHandler}
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <FormControl isRequired>
            <FormLabel>उमेर</FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="number"
                placeholder="उमेर"
                name="age"
                value={data?.age}
                onChange={changeHandler}
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>ठेगाना</FormLabel>
            <InputGroup>
              <Input
                fontWeight="500"
                type="text"
                placeholder="ठेगाना"
                className="auth_input"
                name="address"
                value={data?.address}
                onChange={changeHandler}
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap={4}>
          <SelectInput
            placeholder="कृपया छान्नुहोस"
            label="प्रदेश"
            required
            value={
              provinces &&
              provinces
                .filter((p: any) => p.id === 6)
                .map((p: any) => {
                  return { value: p?.id, label: p?.province_np };
                })
            }
            name="province_id"
          />
          <SelectInput
            placeholder="कृपया छान्नुहोस"
            label="जिल्ला"
            required
            options={
              districts &&
              districts
                .filter((p: any) => p?.province?.id === 6)
                ?.map((p: any) => {
                  return { value: p?.id, label: p?.district_np };
                })
            }
            on_change={(e: any) => select_change("district_id", e.value)}
            value={
              districts &&
              districts
                .map((p: any) => {
                  return { label: p.district_np, value: p.id };
                })
                .filter(
                  (p: any) =>
                    p.value.toString() === data?.district_id.toString()
                )
            }
            name="district_id"
          />
        </Flex>
        <Flex gap={4}>
          <SelectInput
            placeholder="कृपया छान्नुहोस"
            label="स्थानीय तह"
            required
            options={
              llevel &&
              llevel
                .filter(
                  (p: any) =>
                    p?.district?.id?.toString() ===
                    data?.district_id?.toString()
                )
                ?.map((p: any) => {
                  return { value: p?.id, label: p?.lgname_np };
                })
            }
            on_change={(e: any) => select_change("llevel_id", e.value)}
            value={
              llevel &&
              llevel
                .map((p: any) => {
                  return { label: p.lgname_np, value: p.id };
                })
                .filter(
                  (p: any) => p.value.toString() === data?.llevel_id.toString()
                )
            }
            name="llevel_id"
          />
          <SelectInput
            placeholder="कृपया छान्नुहोस"
            label="जातजाति /जनजाति"
            required
            options={
              castetribes &&
              castetribes.map((p: any) => {
                return { label: p.name, value: p.id };
              })
            }
            on_change={(e: any) => select_change("caste_tribe_id", e.value)}
            value={
              castetribes &&
              castetribes
                .map((p: any) => {
                  return { label: p.name, value: p.id };
                })
                .filter(
                  (p: any) =>
                    p.value.toString() === data?.caste_tribe_id.toString()
                )
            }
            name="caste_tribe_id"
          />
        </Flex>
        <Flex gap={4}>
          <SelectInput
            placeholder="कृपया छान्नुहोस"
            label="लिङ्ग"
            options={
              gender &&
              gender.map((p: any) => {
                return { label: p?.name, value: p.id };
              })
            }
            on_change={(e: any) => select_change("gender_id", e.value)}
            value={
              gender &&
              gender
                .map((p: any) => {
                  return { label: p.name, value: p.id };
                })
                .filter(
                  (p: any) => p.value.toString() === data?.gender_id?.toString()
                )
            }
            name="gender_id"
          />
          <SelectInput
            placeholder="कृपया छान्नुहोस"
            label="अपाङ्गता भएका व्यक्ति"
            options={
              disabilites &&
              disabilites.map((p: any) => {
                return { label: p?.name, value: p.id };
              })
            }
            on_change={(e: any) =>
              select_change("disability_people_id", e.value)
            }
            value={
              disabilites &&
              disabilites
                .map((p: any) => {
                  return { label: p.name, value: p.id };
                })
                .filter(
                  (p: any) =>
                    p.value.toString() ===
                    data?.disability_people_id?.toString()
                )
            }
            name="disability_people_id"
          />
        </Flex>
        <Flex gap={4} width={"49%"}>
          <SelectInput
            placeholder="कृपया छान्नुहोस"
            label="हिंसाको प्रकार"
            required
            options={
              voiliences &&
              voiliences.map((p: any) => {
                return { label: p?.name, value: p.id };
              })
            }
            on_change={(e: any) => select_change("voilence_type_id", e.value)}
            value={
              voiliences &&
              voiliences
                .map((p: any) => {
                  return { label: p.name, value: p.id };
                })
                .filter(
                  (p: any) =>
                    p.value.toString() === data?.voilence_type_id?.toString()
                )
            }
            name="voilence_type_id"
          />
        </Flex>
      </Flex>
      <TabButton />
    </form>
  );
};
export default IncidentData;
