import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { IoKey } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import httpClients from "../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_hook";
import { set_change_password } from "../../redux/extra_field";
import { setLogin } from "../../redux/login";

function PasswordChange(props: { open?: any; close?: any }) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [password, setPassword] = React.useState({
    password: "",
    confirm_password: "",
  });
  const [show_passwords, setShowPasswords] = React.useState({
    second: false,
    third: false,
  });
  const modal = useAppSelector((state) => state.extraField.change_password);
  const [error, setError] = React.useState<boolean>(false);
  const submitPassword = (e: any) => {
    e.preventDefault();
    if (
      password?.password?.toString() === password?.confirm_password?.toString()
    ) {
      httpClients
        .POST(
          "change_password?user_id=3",
          {
            password: password.password,
          },
          true
        )
        .then((res) => {
          toast({
            title: "तपाईँको पासवर्ड सफलतापूर्वक सम्पादन गरिएको छ",
            status: "success",
          });
          dispatch(set_change_password());
          localStorage.clear();
          sessionStorage.clear();
          dispatch(setLogin());
        })
        .catch((err) => {
          toast({
            title: "क्षमा गर्नुहोस् तपाईँको पासवर्ड सम्पादन गरिएन!",
            status: "error",
          });
        });
    } else {
      setError(true);
      toast({ title: "तपाईँले भरेको पासवर्ड मेल खाएन!", status: "error" });
    }
  };
  return (
    <>
      <Modal
        isOpen={modal as any}
        onClose={() => dispatch(set_change_password())}
        isCentered
      >
        <ModalOverlay />
        <form onSubmit={submitPassword}>
          <ModalContent>
            <ModalHeader fontSize={23}>पासवर्ड परिवर्तन गर्नुहोस्</ModalHeader>
            <ModalCloseButton />
            <Divider />
            <ModalBody>
              <Flex direction={"column"} gap={3}>
                <FormControl isRequired isInvalid={error}>
                  <FormLabel>पासवर्ड</FormLabel>
                  <InputGroup>
                    <Input
                      pr="4.5rem"
                      fontWeight="500"
                      type={show_passwords.second ? "text" : "password"}
                      placeholder="पासवर्ड प्रविष्ट गर्नुहोस्"
                      name="password"
                      onChange={(e: any) =>
                        setPassword({
                          ...password,
                          password: e.target.value,
                        })
                      }
                      value={password.password as any}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() =>
                          setShowPasswords({
                            ...show_passwords,
                            second: !show_passwords.second,
                          })
                        }
                      >
                        {show_passwords.second ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl isRequired isInvalid={error}>
                  <FormLabel>पुन: पासवर्ड</FormLabel>
                  <InputGroup>
                    <Input
                      pr="4.5rem"
                      fontWeight="500"
                      type={show_passwords.third ? "text" : "password"}
                      placeholder="पासवर्ड पुन: प्रविष्ट गर्नुहोस्"
                      name="confirm_password"
                      onChange={(e: any) =>
                        setPassword({
                          ...password,
                          confirm_password: e.target.value,
                        })
                      }
                      value={password.confirm_password as any}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() =>
                          setShowPasswords({
                            ...show_passwords,
                            third: !show_passwords.third,
                          })
                        }
                      >
                        {show_passwords.third ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                type="submit"
                mr={3}
                leftIcon={<IoKey />}
              >
                सम्पादन
              </Button>
              <Button
                onClick={() => dispatch(set_change_password())}
                type="button"
                leftIcon={<MdOutlineCancel />}
              >
                रद्द गर्नुहोस्
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
export default PasswordChange;
